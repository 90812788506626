const words = {
ar: {
  'Only lowercase letters are allowed': 'لا يُسمح إلا باستخدام الأحرف الصغيرة',
  'Email is a required field': 'البريد الإلكتروني حقل مطلوب؛',
  'First name is a required field': 'الاسم الأول حقل مطلوب؛',
  'Last name is a required field': 'اسم العائلة حقل مطلوب؛',
  'Username is a required field': 'اسم المستخدم حقل مطلوب؛',
  'Please select a role': 'يُرجى تحديد دور؛',
  'Please enter a role name': 'يُرجى إدخال اسم دور؛',
  'Role name must be unique': 'يجب أن يكون اسم الدور فريدًا؛',
  'Spaces and special characters other than hyphen (-) and underscore (_) are not supported': 'لا يدعم المسافات والأحرف الخاصة بخلاف علامة الوصل (-) والشرطة السفلية (_).',
  'Please enter a location name': 'يُرجى إدخال اسم موقع',
  'Hyphens (-) are the only special characters allowed in the name': 'الواصلات (-) هي الأحرف الخاصة الوحيدة المسموح بها في الاسم؛',
  'Please select a timezone': 'يُرجى تحديد منطقة زمنية؛',
  'Longitude can not be blank': 'لا يُمكن ترك خط الطول فارغًا؛',
  'Latitude can not be blank': 'لا يُمكن ترك خط العرض فارغًا؛',
  'Organization ID is required': 'معرِّف المؤسسة مطلوب؛',
  'Organization is required': 'مطلوب إدخال اسم المؤسسة ',
  'Longitude must be between -180 and 180': 'يجب أن يكون خط الطول بين - 180 و180؛',
  'Latitude must be between -90 and 90': 'يجب أن يكون خط العرض بين - 90 و90؛',
  'Please enter a number': 'يرجى إدخال رقم',
  'Please enter the vendor name': 'يُرجى إدخال اسم المزوّد؛',
  'A vendor with this name already exists': 'يوجد بالفعل مزوّد بهذا الاسم؛',
  'Please enter an ID': 'يُرجى إدخال معرِّف؛',
  'Only letters and underscores are allowed for ID': 'لا يُسمح إلا باستخدام الأحرف والشرطات السفلية لتكوين معرِّف؛',
  'Please select a type': 'يُرجى تحديد نوع؛',
  'A measurement with this ID already exists': 'يوجد بالفعل مقياس لديه هذا المعرِّف؛',
  'Please enter a description': 'يُرجى إدخال وصف؛',
  'Please enter a short name': 'يُرجى إدخال اسم مختصر؛',
  'Please enter a unit': 'يُرجى إدخال وحدة؛',
  'Lower threshold can not be greater than upper threshold': 'يجب ألا تتجاوز العتبة الصغرى العتبة الكبرى؛',
  'Please add a lower threshold': 'يُرجى إضافة عتبة صغرى؛',
  'Please add an upper threshold': 'يُرجى إضافة عتبة كبرى؛',
  'Please select a vendor': 'يُرجى تحديد مزوّد؛',
  'Please enter a name': 'يُرجى إدخال اسم؛',
  'Type input must have a name': 'يجب أن يتضمن إدخال النوع اسمًا؛',
  'Type input must have a measurement': 'يجب أن يتضمن إدخال النوع مقياسًا؛',
  'Type inputs can not use the same measurement': 'لا يُمكن لإدخالات نوع مختلفة استخدام المقياس نفسه؛',
  'Please select an organization': 'يُرجى تحديد مؤسسة؛',
  'Port Address is a required field': 'عنوان المنفذ حقل مطلوب؛',
  'Slave Address is a required field': 'العنوان التابع حقل مطلوب؛',
  'Baud Rate is a required field': 'معدل الباود حقل مطلوب؛',
  'Bytesize is a required field': 'حجم البايت حقل مطلوب؛',
  'Stop Bits is a required field': 'وحدات بت الإيقاف حقل مطلوب؛',
  'Timeout is a required field': 'المهلة حقل مطلوب؛',
  'Read Register count is a required field': 'عدد سجلات القراءة حقل مطلوب؛',
  'Read Register is a required field': 'سجل القراءة حقل مطلوب؛',
  'Read Measurement Type is a required field': 'نوع مقياس القراءة حقل مطلوب؛',
  'Read Measurement Unit is a required field': 'وحدة مقياس القراءة حقل مطلوب؛',
  'The selected ID is already in use': 'المعرِّف المحدد قيد الاستخدام بالفعل؛',
  'Please enter a version': 'يُرجى إدخال نسخة من النسخ',
  'The flash script version already exists': 'توجد بالفعل نسخة نصية\'فلاش\'',
  'Home': 'الرئيسية؛',
  'Environment': 'بيئة؛',
  'Temperature': 'درجة الحرارة؛',
  'Absolute Humidity': 'الرطوبة المطلقة',
  'Air Pressure': 'ضغط الهواء',
  'SVP': 'ضغط البخار المشبع',
  'Fan Speed': 'سرعة المروحة',
  'Airflow': 'تدفق الهواء',
  'Carbon Dioxide': 'ثاني أكسيد الكربون؛',
  'UV Index': 'مؤشر الأشعة فوق البنفسجية',
  'DLI': 'ضوء يومي متكامل',
  'Solar Irradiance': 'الإشعاع الشمسي',
  'VPD': 'نقص ضغط البخار',
  'Relative Humidity': 'الرطوبة النسبية',
  'Fertigation': 'تسميد؛',
  'Water Temperature': 'درجة حرارة المياه؛',
  'Conductivity': 'إمكانية التوصيل؛',
  'pH': 'درجة الحموضة؛',
  'Dissolved Oxygen': 'أكسجين مذاب؛',
  'Dissolved Oxygen Percentage': 'نسبة الأكسجين المذاب',
  'Salinity': 'الملوحة',
  'Liquid Flow': 'تدفق السوائل',
  'Soil Moisture': 'رطوبة التربة',
  'Soil Temperature': 'درجة حرارة التربة',
  'Soil pH': 'درجة حموضة التربة',
  'Soil Conductivity': 'موصلية التربة',
  'Soil P Content': 'محتوى التربة من الفوسفور',
  'Soil K Content': 'محتوى التربة من البوتاسيوم',
  'Soil N Content': 'محتوى التربة من النيتروجين',
  'Plant Science': 'علم النبات؛',
  'Wet Bulb Temperature': 'درجة ميزان الحرارة الرطب',
  'Resources': 'موارد؛',
  'Energy': 'الطاقة؛',
  'Photosynthetically Active Radiation': 'إشعاع نشط ضوئيًا',
  'Power Consumed': 'الطاقة المستهلكة',
  'Actuator State': 'حالة المشغل',
  'Ion Concentration': 'التركيز الأيوني',
  'Power Generation': 'توليد الطاقة',
  'Motor Speed': 'سرعة المحرك',
  'Power Consumption': 'استهلاك الطاقة',
  'Line Voltage': 'الجهد الخطي',
  'Vapour Pressure Deficit': 'نقص ضغط البخار',
  'Wind Speed': 'سرعة الرياح',
  'Energy (kW': 'الطاقة (كيلوواط',
  'RTR': 'RTR',
  'CO2 Flow Rate': 'معدل تدفق ثاني أكسيد الكربون',
  'TDS': 'المواد الصلبة الذائبة',
  'VFD': 'جهاز تردد متغير',
  'UV-C Intensity': 'كثافة الأشعة فوق البنفسجية',
  'Liquid Level': 'مستوى السائل',
  'Growing Degree Hours': 'تزايد ساعات الدرجة',
  'Site': 'موقع؛',
  'Sites': 'مواقع؛',
  'Facility': 'منشأة؛',
  'Facilities': 'منشآت؛',
  'Room': 'قاعة؛',
  'Rooms': 'قاعات؛',
  'Zone': 'منطقة؛',
  'Zones': 'مناطق؛',
  'Subzone': 'المنطقة الفرعية',
  'Subzones': 'مناطق فرعية؛',
  'Current (instantaneous': 'التيار (لحظي',
  'GDH': 'GDH',
  'Line Frequency': 'تردد الخط',
  'Liquid Flow (L/m': 'تدفق السوائل (لتر/دقيقة',
  'PAR': 'الإشعاع النشط ضوئيًا؛',
  'PPFD': 'PPFD',
  'Power Consumption (instantaneous': 'استهلاك الطاقة (لحظي',
  'Power Generation (instantaneous': 'توليد الطاقة (لحظي',
  'Power Generated': 'الطاقة المولدة',
  'Pump Speed': 'سرعة المضخة',
  'Soil N content': 'محتوى النيتروجين في التربة',
  'Soil K content': 'محتوى البوتاسيوم في التربة',
  'Soil P content': 'محتوى الفوسفور في التربة',
  'Wind Direction': 'اتجاه الرياح',
  'Actor': 'العامل',
  'Actor Type': 'نوع العامل',
  'Date': 'التاريخ؛',
  'Event': 'الحدث',
  'Service': 'الخدمة؛',
  'Context': 'السياق',
  'Action': 'الإجراء؛',
  'Details': 'التفاصيل؛',
  'Filters': 'المرشحات',
  'General': 'عام؛',
  'Organization': 'مؤسسة؛',
  'Inspect': 'فحص',
  'N/A': 'N/A',
  'Inspect Logs': 'فحص السجلات',
  'View details of the selected log': 'الاطلاع على تفاصيل السجل المحدد',
  'No audit logs found': 'لم يتم العثور على سجلات تدقيق',
  'Updated Attributes': 'السمات المحدثة',
  'Audit Logs': 'سجلات التدقيق',
  'View audit logs': 'الاطلاع على سجلات التدقيق',
  'Filter by actor type': 'الفرز حسب نوع العامل',
  'Filter by organization': 'الفرز حسب المؤسسة',
  'Filter by action': 'تصفية حسب ا',
  'Filter by service': 'الفرز حسب الخدمة',
  'Search context': 'سياق البحث',
  'Filter by actor': 'الفرز حسب العامل',
  'Filter by': 'فرز حسب',
  'Reset all fields': 'إعادة ضبط كل الحقول',
  'Refresh data': 'تحديث البيانات',
  'Location & Service': 'الموقع والخدمة',
  'Operations': 'عمليات؛',
  'Management': 'الإدارة؛',
  'Thresholds': 'العتبات؛',
  'Timestream Data': 'بيانات التدفق الزمني',
  'Device': 'الجهاز؛',
  'Calibration': 'المعايرة؛',
  'Video': 'فيديو',
  'Weather': 'الطقس',
  'Manager': 'المدير',
  'Device Management': 'إدارة الأجهزة؛',
  'Create': 'إنشاء',
  'Update': 'تحديث',
  'Delete': 'حذف؛',
  'User': 'المستخدم؛',
  'System': 'النظام',
  'This cannot be changed later.': 'لا يمكن تغيير هذا في وقت لاحقًا.',
  'Characters left: ': 'الأحرف المتبقية: ',
  'Hardware': 'جهاز',
  'Calculation': 'حساب',
  'Software': 'برمجيات',
  'Sensor': 'مستشعر؛',
  'Camera': 'كاميرا؛',
  'Motor': 'محرك؛',
  'Pump': 'مضخة؛',
  'Valve': 'صمام؛',
  'Switch': 'مفتاح؛',
  'Core': 'مركز؛',
  'Pyranometer': 'مقياس الإشعاع',
  'Energy Monitor': 'مراقب الطاقة',
  'Weather Station': 'محطة الأرصاد الجوية',
  'Envirosense': 'Envirosense؛',
  'Soilsense': 'نظام تحسس الأرض \'Soilsense\'',
  'Envirosense Modbus': 'Envirosense Modbus',
  'Water Flow Meter': 'مقياس تدفق المياه',
  'Watersense': 'Watersense؛',
  'Airflow Meter': 'مقياس تدفق الهواء',
  'Title is required': 'هذه البيانات مطلوبة',
  'Help text is required': 'نص المساعدة مطلوب',
  'Upload logo as PNG': '',
  'Label': 'علامة مميزة',
  'Replacement Label': 'علامة الاستبدال',
  'Replacement Label Plural': 'علامة الاستبدال الجمعي',
  'Replacement Label Language': 'لغة علامة الاستبدال',
  'Edit': 'تحرير؛',
  'Actions': 'الإجراءات؛',
  'Translations': 'الترجمات',
  'Language': 'اللغة',
  'Singular': 'مفرد',
  'Plural': 'جمع',
  'Redo Translations': 'إعادة ترجمة الترجمات',
  'Edit Label': 'تحرير العلامة المميزة',
  'Select a label to replace': 'حدد علامة مميزة للاستبدال',
  'City, Section, Row, Seat, etc.': 'المدينة، القسم، الصف، المقعد، إلخ.',
  'Cities, Sections, Rows, Seats, etc.': 'المدن، الأقسام، الصفوف، المقاعد، إلخ.',
  'After creating the label, we will automatically translate it into the other languages.': 'بعد إنشاء العلامة المميزة، سوف نقوم بترجمتها تلقائيا إلى اللغات الأخرى.',
  'Cancel': 'إلغاء؛',
  'Update Label': 'تحديث العلامة المميزة',
  'Create Label': 'إنشاء العلامة المميزة',
  'English': 'الإنجليزية؛',
  'Spanish': '',
  'Arabic': 'الإسبانية',
  'Label is a required field.': 'حقل العلامة المميزة حقل مطلوب.',
  'Replacement label is a required field.': 'حقل العلامة المميزة للاستبدال حقل مطلوب',
  'Select the language of the replacement label': 'حدد لغة العلامة المميزة للاستبدال',
  'Replacement label plural is a required field.': 'حقل العلامة المميزة الجمعية للاستبدال حقل مطلوب',
  'Label ID is required': 'رمز تعريف العلامة المميزة مطلوب',
  'Manage': 'إدارة ',
  'Oganization': 'العلامات المميزة',
  'Labels': 'للمؤسسة',
  'Add Label': 'إضافة علامة مميزة',
  'Options': 'الخيارات',
  'Please enter an organization name': 'يُرجى إدخال اسم مؤسسة؛',
  'value': 'القيمة',
  'Add': 'إضافة',
  'Add new': 'إضافة جديد؛',
  'Enter a unique name and the relevant details for the': 'قم بإدخال اسمً فريدً وتفاصيل ذات الصلة لما يلي',
  'name': 'الاسم',
  'ID': 'المعرّف؛',
  'Enter new details for': 'قم بإدخال تفاصيل جديدة لما  ',
  'with ID': 'مع رقم رمز ',
  'No': 'التعريف',
  'The following': 'يلي',
  'These are the details associated with the': 'هذه هي التفاصيل المقترنة بما يلي',
  'Data API': 'واجهة برمجة التطبيقات للبيانات',
  'User Management': 'إدارة المستخدمين؛',
  'User Roles': 'أدوار المستخدمين؛',
  'System Thresholds': 'عتبات النظام؛',
  'Control Range Management': 'إدارة نطاق التحكم؛',
  'No users were found': 'لم يتم العثور على مستخدمين؛',
  'Add User': 'إضافة مستخدم؛',
  'Role': 'الدور؛',
  'Username': 'اسم المستخدم؛',
  'Registration Date': 'تاريخ التسجيل؛',
  'Status': 'الحالة؛',
  'Last Seen': 'آخر ظهور؛',
  'Add Role': 'إضافة دور؛',
  'Organizations': 'مؤسسات؛',
  'Camera Access': 'الوصول إلى الكاميرا؛',
  'Sensor Control': 'التحكم في المستشعر؛',
  'Threshold Admin': 'مسؤول العتبة؛',
  'View': 'عرض؛',
  'Remove User': 'إزالة مستخدم؛',
  'Loading organizations': 'جارٍ تحميل المؤسسات؛',
  'Loading users': 'جارٍ تحميل المستخدمين؛',
  'Loading roles': 'جارٍ تحميل الأدوار؛',
  'Loading thresholds': 'جارٍ تحميل العتبات؛',
  'Search by user name': 'قم بالبحث باسم المستخدم',
  'Search by role name': 'قم بالبحث بمسمى الوظيفة',
  'Search Devices': 'بحث في الأجهزة؛',
  'Search by name': 'بحث بالاسم؛',
  'Vendors': 'مزوّدون؛',
  'View and create device vendors': 'عرض وإنشاء مزوّدي الأجهزة؛',
  'Add vendor': 'إضافة مزوّد؛',
  'Create a new device vendor': 'إنشاء مزوّد أجهزة جديد؛',
  'Save vendor': 'حفظ المزوّد؛',
  'Vendor name': 'اسم المزوّد؛',
  'Edit vendor': 'تحرير مزوّد؛',
  'Update vendor details': 'تحديث تفاصيل المزوّد؛',
  'Loading vendors': 'جارٍ تحميل المزوّدين؛',
  'Vendor updated': 'تم تحديث المزوّد؛',
  'Vendor created': 'تم إنشاء المزوّد؛',
  'Types': 'الأنواع؛',
  'View and create device types': 'عرض وإنشاء أنواع الأجهزة؛',
  'Add type': 'إضافة نوع؛',
  'Create a new device type': 'إنشاء نوع جهاز جديد؛',
  'Save type': 'حفظ النوع؛',
  'Vendor': 'المزوّد؛',
  'Name': 'الاسم؛',
  'Model': 'الطراز؛',
  'Model version': 'إصدار الطراز؛',
  'Firmware version': 'إصدار البرنامج الثابت؛',
  'Communications protocol (separate entries by comma': 'بروتوكول الاتصالات (افصل بين الإدخالات بفاصلة؛',
  'Edit type': 'تحرير نوع؛',
  'Update type details': 'تحديث تفاصيل النوع؛',
  'Type inputs': 'إدخالات النوع؛',
  'Lower threshold in': 'العتبة الصغرى في؛',
  'Upper threshold in': 'العتبة الكبرى في؛',
  'Remove': 'إزالة؛',
  'Accept': 'قبول؛',
  'Select a measurement type': 'تحديد نوع مقياس؛',
  'Loading types': 'جارٍ تحميل الأنواع؛',
  'Type updated': 'تم تحديث النوع؛',
  'Type created': 'تم إنشاء النوع؛',
  'Measurements': 'مقاييس؛',
  'View and create measurement types with custom threshold ranges': 'عرض وإنشاء أنواع المقاييس بنطاقات عتبات مخصصة؛',
  'Add measurement': 'إضافة مقياس؛',
  'Create a new device measurement': 'إنشاء مقياس جهاز جديد؛',
  'Save measurement': 'حفظ المقياس؛',
  'Type': 'النوع؛',
  'Select a type': 'حدد نوعًا؛',
  'Description': 'الوصف؛',
  'Short name': 'الاسم المختصر؛',
  'Unit of measurement': 'وحدة القياس؛',
  'Specify threshold range': 'تحديد نطاق عتبة؛',
  'Upper threshold': 'العتبة الكبرى؛',
  'Lower threshold': 'العتبة الصغرى؛',
  'Edit measurement': 'تحرير مقياس؛',
  'Update measurement details': 'تحديث تفاصيل المقياس؛',
  'Measurement updated': 'تم تحديث المقياس؛',
  'Measurement created': 'تم إنشاء المقياس؛',
  'Filter by type': 'تصفية حسب النوع؛',
  'Flash Scripts': 'لغة برمجة نصية \'فلاش\'',
  'View and create flash scripts': 'الاطلاع على، وإنشاء برامج نصية \'فلاش\'',
  'No available flash scripts': 'لا تتوفر برامج نصية \'فلاش\'',
  'Add flash script': 'قم بإضافة برنامج نصي \'فلاش\'',
  'Edit flash script': 'قم بتحرير برنامج نصي \'فلاش\'',
  'Create new flash script': 'قم بإنشاء برنامج نصي \'فلاش\' ',
  'Update flash script details': 'قم بتحديث تفاصيل برنامج نصي \'فلاش\'',
  'Device Type': 'نوع الجهاز',
  'Version': 'النسخة',
  'Flash script debug file': 'ملف تتبع أخطاء برنامج نصي \'فلاش\'',
  'Flash script release file': 'ملف إصدار برنامج نصي \'فلاش\'',
  'Flash script release debug file': 'ملف تتبع أخطاء إصدار برنامج نصي \'فلاش\'',
  'Flash script created successfully.': 'تم إنشاء برنامج نصي \'فلاش\' بنجاح.',
  'Flash script updated successfully.': 'تم تحديث برنامج نصي \'فلاش\' بنجاح.',
  'Device Types': 'أنواع الأجهزة؛',
  'Device has firmware': 'الجهاز يتضمن البرنامج الثابت؛',
  'Device supports Greengrass': 'يدعم الجهاز Greengrass',
  'Add device type': 'إضافة نوع جهاز؛',
  'Edit device type': 'تحرير نوع الجهاز؛',
  'No available device types': 'ليس هناك أنواع أجهزة متاحة',
  'Hardware Version': 'إصدار الجهاز',
  'Firmware Versions': 'إصدارات البرمجيات الثابتة',
  'Flash Script': 'برنامج نصي \'فلاش\'',
  'Select versions': 'حدد الإصدارات',
  'Select version': 'حدد النسخة',
  'Enter version': 'أدخل الإصدار',
  'Add hardware version': 'أضف إصدار الجهاز',
  'No available firmware versions': 'لا تتوفر إصدارات للبرمجيات الثابتة',
  'Create new device type': 'إنشاء نوع جهاز جديد؛',
  'Update device type details': 'تحديث تفاصيل نوع الجهاز؛',
  'Device type created successfully.': 'تم إنشاء نوع الجهاز بنجاح.؛',
  'Device type updated successfully.': 'تم تحديث نوع الجهاز بنجاح.؛',
  'Add New User': 'إضافة مستخدم جديد؛',
  'Create a new user and add them to the default zones.': 'أنشئ مستخدمًا جديدًا وأضِفه إلى المناطق الافتراضية.؛',
  'Edit User Profile': 'تحرير ملف تعريف المستخدم؛',
  'Edit user settings.': 'تحرير إعدادات المستخدم؛',
  'View User Profile': 'عرض ملفات تعريف المستخدم؛',
  'View user settings.': 'عرض إعدادات المستخدم؛',
  'User Details': 'تفاصيل المستخدم؛',
  'Assign Role': 'تعيين دور؛',
  'User Role': 'دور المستخدم؛',
  'First name': 'الاسم الأول؛',
  'Last name': 'اسم العائلة؛',
  'Email': 'البريد الإلكتروني؛',
  'Phone Number': 'رقم الهاتف؛',
  'Assign Defaults': 'تعيين الإعدادات الافتراضية؛',
  'Save User Details': 'حفظ تفاصيل المستخدم؛',
  'Reset Fields': 'إعادة تعيين الحقول؛',
  'Remove user': 'إزالة مستخدم؛',
  'You are about to permanently remove a user.': 'أنت على وشك إزالة مستخدم نهائيًا.؛',
  'Delete user': 'حذف مستخدم؛',
  'All roles': 'كل الوظائف',
  'All organizations': 'كل المؤسسات؛',
  'Please complete all fields in the form.': 'يُرجى إكمال كل الحقول بالنموذج.؛',
  'Please provide a valid phone number.': 'يُرجى توفير رقم هاتف صحيح.؛',
  'New user created.': 'تم إنشاء المستخدم الجديد.؛',
  'New user could not be created.': 'تعذر إنشاء المستخدم الجديد.؛',
  'User details updated.': 'تم تحديث تفاصيل المستخدم.؛',
  'User could not updated.': 'تعذر تحديث المستخدم.؛',
  'User deleted.': 'تم حذف المستخدم.؛',
  'User could not be deleted.': 'تعذر حذف المستخدم.؛',
  'New role created.': 'تم إنشاء الدور الجديد.؛',
  'New role could not be created.': 'تعذر إنشاء الدور الجديد.؛',
  'Role deleted.': 'تم حذف الدور.؛',
  'Role could not be deleted.': 'تعذر حذف الدور.؛',
  'Role permission added.': 'تمت إضافة إذن الدور.؛',
  'Role permission not updated.': 'لم يتم تحديث إذن الدور.؛',
  'Role permission removed.': 'تمت إزالة إذن الدور.؛',
  'Role permission could not be removed.': 'تعذرت إزالة إذن الدور.؛',
  'Role not fetched.': 'لم يتم جلب الدور.؛',
  'Roles not fetched.': 'لم يتم جلب الأدوار.؛',
  'Role permissions not fetched.': 'لم يتم جلب أذونات الدور.؛',
  'Role users not fetched.': 'لم يتم جلب مستخدمي الدور.؛',
  'Invitation sent.': 'تم إرسال الدعوة',
  'Invitation could not be sent.': 'تعذر إرسال الدعوة.',
  'Permission Settings': 'إعدادات الأذونات؛',
  'Manage the permissions granted to users with this role': 'إدارة الأذونات الممنوحة للمستخدمين القائمين بهذا الدور؛',
  'Reset to previous state': 'إعادة تعيين إلى الحالة السابقة؛',
  'Loading permissions': 'جارٍ تحميل الأذونات؛',
  'Use the main toggle to enable viewing the': 'استخدام التبديل الرئيسي لتمكين عرض؛',
  'Use the main toggle to enable editing the': 'استخدام التبديل الرئيسي لتمكين تحرير؛',
  'for all zones.': 'لجميع المناطق.؛',
  'No zones found for organization': 'لم يتم العثور على مناطق للمؤسسة؛',
  'The page will refresh to get the latest zones per organization.': 'سيتم تحديث الصفحة للحصول على أحدث المناطق الخاصة بكل مؤسسة.؛',
  'Add new role': 'إضافة دور جديد؛',
  'Enter a unique name for the role, and select a base role to use as a template.': 'أدخل اسمًا فريدًا للدور وحدد دورًا أساسيًا لاستخدامه كقالب.؛',
  'Role name': 'اسم الدور؛',
  'Base role (inherit permissions from existing role': 'دور أساسي (وراثة الأذونات من دور موجود؛',
  'Close': 'إغلاق؛',
  'Create new role': 'إنشاء دور جديد؛',
  'Select a base role': 'تحديد دور أساسي؛',
  'Latitude': 'خط العرض؛',
  'Longitude': 'خط الطول؛',
  'Timezone region': 'المنطقة الزمنية؛',
  'Code': 'الرمز؛',
  'Data Ingestion Frequency': 'وتيرة استيعاب البيانات',
  'Enable AI Forecast': 'تمكين توقعات الذكاء الاصطناعي',
  'Enable Maintenance Mode': 'تمكين وضع الصيانة',
  'Enable Trial Mode View': '',
  'Coordinates:': 'الإحداثيات:؛',
  'Coordinates are undefined.': 'الإحداثيات غير مُحددة.؛',
  'Select a timezone': 'تحديد منطقة زمنية؛',
  'Select a status': 'تحديد حالة؛',
  'Save': 'حفظ؛',
  'Current location': 'الموقع الحالي؛',
  'Select a new location': 'تحديد موقع جديد؛',
  'Show provisioning config': 'إظهار إعدادات تكوين التزويد؛',
  'Download Provision Installer': 'تنزيل مثبِّت التزويد؛',
  'Copy to clipboard': 'نسخ إلى الحافظة؛',
  'Generate': 'إنشاء؛',
  '1 - Download the Zip file and transfer it into the intended Greengrass device.': '1 - نزّل ملف Zip وانقله إلى جهاز Greengrass المقصود.؛',
  '2 - On a terminal window in the device, navigate to the downloaded Zip file. Normally this is located at /home/user/downloads': '2 - في نافذة طرفية بالجهاز، انتقل إلى ملف Zip الذي تم تنزيله. يقع هذا الملف عادةً في/الصفحة الرئيسية/خانة المستخدم/خانة التنزيلات',
  '3 - Unzip the Zip file using an unzip tool for Linux on the command line.': '3 - فُك ضغط ملف Zip باستخدام أداة فك ضغط تناسب نظام Linux في سطر الأوامر.؛',
  '4 - Navigate to the Zip file and then execute the setup.sh script.': '4 - انتقل إلى ملف Zip، ثم نفِّذ البرنامج النصي setup.sh.؛',
  '5 - Execute the following command: sudo /bin/bash setup.sh': '5 - نفِّذ الأمر التالي: sudo /bin/bash setup.sh؛',
  'NOTE: The setup.sh script will install all required third party tools and set up the Greengrass nucleus as a system service.': 'ملاحظة: سيثبِّت البرنامج النصي setup.sh كل أدوات الجهات الخارجية المطلوبة ويقوم بإعداد مركز Greengrass بوصفه خدمة للنظام.؛',
  'Greengrass Core Device': 'جهاز Greengrass الأساسي؛',
  'No core device set up yet.': 'لم يتم إعداد جهاز أساسي بعد.؛',
  'No deployments for this core device.': 'لا توجد عمليات نشر لهذا الجهاز الأساسي.؛',
  'Create default deployment': 'إنشاء عملية نشر افتراضي؛',
  'Show deployments': 'إظهار عمليات النشر؛',
  'Deployments': 'عمليات النشر؛',
  'Reason': 'السبب؛',
  'Refetch core device': 'إعادة جلب الجهاز الأساسي؛',
  'Provisioning Config': 'إعدادات تكوين التزويد؛',
  'Timezone': 'المنطقة الزمنية؛',
  'The following thresholds are part of': 'العتبات التالية هي جزء من؛',
  'Managers': 'المديرون:',
  'The following users can manage the location': 'يُمكن للمستخدمين التاليين إدارة الموقع',
  'Fleet Management': 'إدارة الأسطول',
  'Core devices and sensor management for': 'الأجهزة الأساسية وإدارة الاستشعار لما يلي',
  'OTA update by sensor': 'التحديث عن بعد بجهاز الاستشعار',
  'OTA update by core device': 'التحديث عن بعد بجهاز أساسي',
  'Core device update': 'تحديث الجهاز الأساسي',
  'Query NVS data': 'الاستعلام عن بيانات \'NVS\'',
  'Query device and sensor status': 'الاستعلام عن وضع الجهاز وأداة الاستشعار',
  'Request SIM diagnostic data': '',
  'Request SIM diagnostic ping': '',
  'Select one more devices and/or sensors from the list below, followed by the action.': 'قم بتحديد أجهزة أخرى و/أو أدوات استشعار أخرى من القائمة التالية، متبوعا بالإجراء المطلوب.',
  'Select one more core devices from the list below, followed by the action.': 'قم بتحديد أجهزة أساسية أخرى من القائمة التالية، متبوعة بالإجراء المطلوب.',
  'Select one more sensors from the list below, followed by the action.': 'قم بتحديد أدوات استشعار أخرى من القائمة التالية، متبوعة بالإجراء المطلوب.',
  'Command & Control': 'القيادة والسيطرة',
  'Control pumps and valves with Greengrass in': 'التحكم في المضخات والمحابس باستخدام تكنولوجيا \'جرين جراس\'',
  'Edit settings': 'تعديل الإعدادات',
  'Ingestion frequency updated': 'تم تحديث وتيرة الاستيعاب',
  'The selected values are already applied.': 'القيم المحددة مطبقة بالفعل.',
  'Reset selection': 'إعادة ضبط الاختيارات',
  'Devices Selected': 'الأجهزة المحددة',
  'Sensors Selected': 'أدوات الاستشعارات المحددة',
  'Devices Active': 'الأجهزة نشطة',
  'Sensors Active': 'أدوات الاستشعار نشطة',
  'Perform Action': 'القيام بالإجراء المطلوب',
  'Running': 'جارٍ العمل',
  'Unknown': 'غير معروف',
  'Add control device configuration': 'قم بإضافة تهيئة لجهاز التحكم',
  'Edit control device configuration': 'قم بتعديل تهيئة جهاز التحكم',
  'Specify control device specification': 'قم بتحديد مواصفات جهاز التحكم',
  'Add configuration': 'قم بإضافة تهيئة ',
  'Edit configuration': 'قم بتعديل التهيئة',
  'Delete configuration': 'قم بحذف التهيئة',
  'View specification': 'الاطلاع على المواصفات',
  'No control devices configured.': 'لم يتم تهيئة أي أجهزة تحكم.',
  'Address': 'العنوان؛',
  'Slave address': 'العنوان التابع',
  'Baudrate': 'معدل الإرسال',
  'Relay configuration': 'تهيئة المرحلات',
  'Relay ID': 'الرمز التعريفي للمرحل',
  'Register number': 'رقم التسجيل',
  'Select sensor': 'قم بتحديد أداة استشعار',
  'Select relay by ID': 'قم بتحديد المرحل بالرمز التعريفي',
  'Measurement': 'المقياس؛',
  'Select measurement': 'قم بتحديد المقياس',
  'Configure conditions': 'تهيئة الأوضاع',
  'Minimum': 'الحد الأدنى',
  'Enter min value': 'أدخل القيمة الدنيا',
  'Maximum': 'الحد الأقصى',
  'Enter max value': 'أدخل القيمة القصوى',
  'Add specification': 'قم بإضافة المواصفات',
  'Remove configuration from Greengrass core device': 'قم بإزالة التهيئة من جهاز \'جرين جراس\' الأساسي',
  '\'Are you sure you want to remove this configuration? It will remove any specification attached to it. This action cant be undone.\'': '\'هل أنت متأكد من أنك تريد إزالة هذه التهيئة؟ سيؤدي ذلك إلى إزالة أية مواصفات مرفقة بها. هذا الإجراء لا يمكن الرجوع فيه.\'',
  'Remove relay from device configuration': 'قم بإزالة المرحل من تهيئة الجهاز',
  '\'Are you sure you want to remove this relay? It currently has a specification attached to it. This action cant be undone.\'': '\'هل أنت متأكد من أنك تريد إزالة هذا المرحل؟ توجد مواصفات مرفقة به حاليا هذا الإجراء لا يمكن الرجوع فيه.\'',
  'Remove the': 'إزالة الـ؛',
  'role': 'الدور؛',
  'You are about to remove a role.': 'أنت على وشك إزالة دور.؛',
  'Select a fallback role for affected users.': 'حدد دورًا احتياطيًا للمستخدمين المتأثرين.؛',
  'Select a fallback role': 'تحديد دور احتياطي؛',
  'Affected users': 'المستخدمون المتأثرون؛',
  'You can not delete a role that has users. Please assign a new role to each of the following users to continue.': 'لا يمكنك حذف دور له مستخدمين. للمتابعة، يرجى تخصيص دور جديد لكل مستخدم من المستخدمين التاليين.',
  'Delete role': 'حذف دور؛',
  'No users are currently assigned this role. This role can be safely deleted.': 'لم يتم تخصيص مستخدمين حاليًا لهذا الدور. يمكن حذف هذا الدور بأمان.',
  'No available devices.': 'لا توجد أجهزة متاحة.؛',
  'Location': 'الموقع؛',
  'Tag': 'العلامة؛',
  'Created': 'تم الإنشاء؛',
  'Firmware': 'البرنامج الثابت؛',
  'In use': '',
  'Status last updated': 'التحديث الأخير للوضع',
  'Select an action': 'قم بتحديد إجراءً',
  'Active': 'نشط؛',
  'Unreachable': 'يتعذر الوصول إليه',
  'Pending': 'معلق؛',
  'Modbus': 'بروتوكول Modbus؛',
  'IoT': 'IoT؛',
  'Release': 'إصدار؛',
  'Release Debug': 'إصدار التصحيح؛',
  'Debug': 'تصحيح؛',
  'Greengrass': 'Greengrass',
  'Direct Connect': 'Direct Connect',
  'Enroll device': 'تسجيل جهاز؛',
  'Update type': 'نوع التحديث؛',
  'Regenerate firmware': 'إعادة إنشاء البرنامج الثابت؛',
  'Download firmware': 'تنزيل البرمجيات الثابتة',
  'Configure Device': 'تهيئة الجهاز',
  'Ionsense': 'Ionsense؛',
  'Solarsense': 'Solarsense؛',
  'Devices': 'الأجهزة؛',
  'The following devices are part of': 'الأجهزة التالية هي جزء من؛',
  'Enroll new device': 'تسجيل جهاز جديد',
  'Add new device': 'إضافة جهاز جديد؛',
  'Add device': 'إضافة جهاز؛',
  'Edit device': 'تحرير جهاز؛',
  'Refresh': 'تحديث؛',
  'Fetch supplier device': 'قم بإحضار جهاز المورد',
  'Create new device': 'إنشاء جهاز جديد؛',
  'Update device': 'قم بتحديث الجهاز',
  'Device Version': 'إصدار الجهاز',
  'Connection Method': 'طريقة التوصيل',
  'Connection Type': 'نوع التوصيل',
  'Is IoT Sensor?': 'هل هو مستشعر IoT؟؛',
  'Hardware Version Name': 'اسم إصدار الجهاز',
  'Please select a version': 'يرجى تحديد الإصدار',
  'Create a new device in': 'إنشاء جهاز جديد في؛',
  'Edit existing device in': 'تحرير جهاز موجود في؛',
  'Device created successfully.': 'تم إنشاء الجهاز بنجاح.؛',
  'Device updated successfully.': 'تم تحديث الجهاز بنجاح.؛',
  'Device deleted successfully.': 'تم حذف الجهاز بنجاح.؛',
  'Unenroll': 'إلغاء التسجيل',
  'Select a type of device': 'تحديد نوع الجهاز؛',
  'Display Configuration': 'عرض إعدادات التكوين؛',
  'Remove Device': 'إزالة الجهاز؛',
  'Are you sure you want to remove this device?': 'هل تريد بالتأكيد إزالة هذا الجهاز؟؛',
  'Unenroll Device': 'قم بإلغاء تسجيل الجهاز',
  'Are you sure you want to unenroll this device? You will need to start the enrollment process again.': 'هل أنت متأكد من أنك تريد إلغاء تسجيل هذا الجهاز؟ سوف تحتاج إلى إعادة بدء عملية التسجيل مرة أخرى.',
  'Are you sure you want to update the type of this device?': 'هل تريد بالتأكيد تحديث نوع هذا الجهاز؟؛',
  'Request NVS dump': 'طلب تفريغ التخزين NVS',
  'No timestamp': 'لا يوجد طابع زمني',
  'No tag set': 'لا يوجد إعداد للعلامات',
  'Get current coordinates': 'الحصول على الإحداثيات الحالية',
  'These coordinates are based on your current location. Stand as close to the device as possible to get the most accurate location coordinates.': 'هذه الإحداثيات تستند إلى موقعك الحالي. قف بالقرب من الجهاز قدر الإمكان للحصول على أدق إحداثيات للموقع.',
  'Refetch location': 'إعادة ضبط الموقع',
  'Use coordinates': 'قم باستخدام الإحداثيات',
  'Unavailable': 'غير متوفر؛',
  'SecondSky Data does not have permission to access to your location. Update your browser settings to enable this feature.': '',
  'The device location has changed. Please update the coordinates to match the new location.': 'لقد تغير موقع الجهاز. يُرجى تحديث الإحداثيات لتتطابق مع الموقع الجديد.',
  'Filter by tags': 'الفرز بالعلامات',
  'Port Address': 'عنوان المنفذ؛',
  'Slave Address': 'العنوان التابع؛',
  'Baud Rate': 'معدل الباود؛',
  'Byte Size': 'الحجم بالبايت؛',
  'Stop Bits': 'وحدات بت الإيقاف؛',
  'Timeout': 'المهلة؛',
  'Multiple register reads': 'قراءات سجل متعددة؛',
  'Select Type': 'تحديد النوع؛',
  'Read Registers': 'سجلات القراءة؛',
  'Read Measurement Type': 'نوع مقياس القراءة؛',
  'Read Measurement Unit': 'وحدة مقياس القراءة؛',
  'Register': 'السجل؛',
  'Count': 'العدد؛',
  'Unit': 'الوحدة؛',
  'Add new manager': 'إضافة مدير جديد؛',
  'Select a user and notification channels for a location': 'قم بتحديد مستخدم وقنوات إخطار لموقع ما',
  'Edit manager': 'تحرير مدير؛',
  'Edit notification channels for user in a location': 'قم بتعديل قنوات الإخطار لمستخدم في موقع ما',
  'Add manager': 'إضافة مدير؛',
  'Settings': 'الإعدادات؛',
  'No available managers.': 'لا يوجد مديرون متاحون.؛',
  'An error occurred': 'حدث خطأ؛',
  'Push Notification': 'إشعار منبثق؛',
  'SMS': 'رسالة نصية قصيرة؛',
  'Web Push': 'إشعارات عبر الويب؛',
  'WhatsApp': 'واتساب',
  'Please select a user': 'يُرجى تحديد مستخدم؛',
  'Create new manager': 'إنشاء مدير جديد؛',
  'Update manager': 'تحديث مدير؛',
  'Manager created successfully.': 'تم إنشاء المدير بنجاح.؛',
  'Manager updated successfully.': 'تم تحديث المدير بنجاح.؛',
  'Manager deleted successfully.': 'تم حذف المدير بنجاح.؛',
  'You are about to remove a manager.': 'أنت على وشك إزالة مدير.؛',
  'Delete manager': 'حذف مدير؛',
  'The selected settings are already applied.': 'الإعدادات المحددة مطبقة بالفعل.',
  'Add threshold': 'إضافة عتبة؛',
  'Message': 'رسالة؛',
  'No available thresholds.': 'لا توجد عتبات متاحة.؛',
  'Edit Threshold': 'تحرير عتبة؛',
  'Add New Threshold': 'إضافة عتبة جديدة؛',
  'Save Threshold': 'حفظ العتبة؛',
  'New threshold created.': 'تم إنشاء العتبة الجديدة.؛',
  'Threshold details updated.': 'تم تحديث تفاصيل العتبة.؛',
  'Threshold deleted.': 'تم حذف العتبة.؛',
  'threshold from': 'عتبة من؛',
  'You are about to remove a threshold.': 'أنت على وشك إزالة عتبة.؛',
  'Delete threshold': 'حذف عتبة؛',
  'When value is above': 'عندما تكون القيمة أعلى من',
  'When value is below': 'عندما تكون القيمة أدنى من',
  'Upper Bound Conditions': 'شروط الحد الأكبر؛',
  'Lower Bound Conditions': 'شروط الحد الأصغر؛',
  'Upper bound': 'الحد الأعلى',
  'Lower bound': 'الحد الأدنى',
  'Conditions fall outside of the optimal range. You should keep an eye on this measurement and take action if it does not stabilise.': 'توجد الأوضاع خارج النطاق الأمثل. يجب عليك مراقبة هذا المقياس واتخاذ الإجراء اللازم إذا لم يحقق الاستقرار.',
  'Conditions fall outside of the acceptable range. You should take action to prevent reaching a stage where damage occurs.': 'توجد الأوضاع خارج النطاق المقبول. يجب عليك اتخاذ إجراء لمنع الوصول لمرحلة يحدث فيها ضرر.',
  'Conditions are adverse. You should take action as soon as possible to prevent irreversible damage.': 'الظروف معاكسة. يجب عليك اتخاذ إجراء في أقرب وقت ممكن لمنع وقوع ضرر لا رجعة فيه.',
  'Cameras': 'كاميرات؛',
  'Permissions': 'أذونات؛',
  'Data': 'بيانات؛',
  'Users': 'مستخدمون؛',
  'Groups': 'مجموعات؛',
  'Docs': 'وثائق؛',
  'Roles': 'أدوار؛',
  'Systems': 'أنظمة',
  'Enroll Device': 'تسجيل جهاز؛',
  'Search Device': 'بحث في الجهاز؛',
  'Download Firmware': 'تنزيل البرنامج الثابت؛',
  'Firmware Downloaded': 'تم تنزيل البرنامج الثابت؛',
  'Download Device Firmware': 'تنزيل البرنامج الثابت للجهاز؛',
  'Device Firmware Downloaded': 'تم تنزيل البرنامج الثابت للجهاز؛',
  'Enroll Ethernet Device': 'تسجيل جهاز Ethernet؛',
  'Enroll Wifi Device': 'تسجيل جهاز Wifi؛',
  'Pair': 'إقران؛',
  'Paired': 'تم الإقران؛',
  'Pair with Device': 'إقران مع الجهاز؛',
  'Paired with Device': 'تم الإقران مع الجهاز؛',
  'Device Name Prefix': 'بادئة اسم الجهاز؛',
  'Connect': 'توصيل؛',
  'Connected': 'تم التوصيل؛',
  'Establish Secure Connection': 'إنشاء اتصال آمن؛',
  'Established Secure Connection': 'تم إنشاء اتصال آمن؛',
  'SSID': 'SSID؛',
  'Password': 'كلمة المرور؛',
  'Configure': 'تكوين؛',
  'Configured': 'تم تكوين الإعدادات؛',
  'Send Wifi Config': 'إرسال إعدادات تكوين شبكة Wifi؛',
  'Wifi Config Sent': 'تم إرسال إعدادات تكوين شبكة Wifi؛',
  'Connect Device': 'توصيل الجهاز؛',
  'Device Connected': 'تم توصيل الجهاز؛',
  'Connect Device to Wifi': 'توصيل الجهاز بشبكة Wifi؛',
  'Device Connected to Wifi': 'تم توصيل الجهاز بشبكة Wifi؛',
  'Set Location': 'تعيين الموقع؛',
  'Location Set': 'تم تعيين الموقع؛',
  'Set Device Location': 'تعيين موقع الجهاز؛',
  'Device Location Set': 'تم تعيين موقع الجهاز؛',
  'Set Tag': 'تعيين علامة؛',
  'Tag Set': 'تم تعيين العلامة؛',
  'Set Device Tag': 'تعيين علامة جهاز؛',
  'Device Tag Set': 'تم تعيين علامة الجهاز؛',
  'Max. 255 characters': '255 حرفًا كحد أقصى؛',
  'Disable device': '',
  'Successfully enrolled device': 'تم تسجيل الجهاز بنجاح؛',
  'View Device': 'عرض جهاز؛',
  'Enroll Another Device': 'تسجيل جهاز آخر؛',
  'An Error Occurred': 'حدث خطأ؛',
  'All': 'الكل؛',
  'No organizations found': 'لم يتم العثور على مؤسسات؛',
  'Create new organization': 'إنشاء مؤسسة جديدة؛',
  'Add organization': 'إضافة مؤسسة؛',
  'Save organization': 'حفظ المؤسسة؛',
  'New organization created.': 'تم إنشاء المؤسسة الجديدة.؛',
  'Edit organization': 'تحرير مؤسسة.؛',
  'Organization updated.': 'تم تحديث المؤسسة.؛',
  'Search by organization name': 'بحث باسم المؤسسة؛',
  'Select an organization': 'قم بتحديد مؤسسة',
  'Role organizations': 'مؤسسات دور؛',
  'To enable further customization, please navigate to the edit view once it\\s created.': '',
  'Logs': 'السجلات؛',
  'View activity logs for': 'عرض سجلات الأنشطة لـ؛',
  'Time': 'الوقت؛',
  'Successful logins': 'عمليات تسجيل دخول ناجحة؛',
  'Loading logs': 'جارٍ تحميل السجلات؛',
  'Unsuccessful logins': 'عمليات تسجيل دخول غير ناجحة؛',
  'Active sessions': 'جلسات عمل نشطة؛',
  'No logs to display': 'لا توجد سجلات لعرضها؛',
  'Fetch logs': 'جلب السجلات؛',
  'Start date': 'تاريخ البدء',
  'End date': 'تاريخ الانتهاء',
  'Usage plan': 'خطة الاستخدام',
  'There is no usage plan for this organization.': 'لا توجد خطة استخدام لهذه المؤسسة.',
  'Throttle': 'خانق',
  'Burst limit': 'حدود الانفجار',
  'Rate limit': 'الحد الأقصى للاستخداك',
  'Quota': 'معدل',
  'Limit': 'حد',
  'Offset': 'موازن',
  'Period': 'فترة',
  'Add usage plan': 'أضف خطة استخدام',
  'View usage plan': 'عرض خطة الاستخدام',
  'Confirm new usage plan': 'تأكيد خطة الاستخدام الجديدة',
  'Are you sure you want to add a usage plan for this organization?': 'هل أنت متأكد من أنك تريد إضافة خطة استخدام لهذه المؤسسة؟',
  'Confirm': 'تأكيد',
  'Save changes': 'حفظ التغييرات',
  'Edit usage plan': 'تعديل خطة الاستخدام',
  'is required': 'مطلوب',
  'must be a number': 'يجب أن يكون رقما',
  'No values have been changed': 'لم يتم تغيير أي قيم',
  'Feature Flags': 'علامات الخصائص',
  'Suppliers': 'المورِّدون؛',
  'Go back': 'عودة للخلف',
  'FYI': 'لمعلوماتك',
  'Help text': 'نص للمساعدة',
  'Manage help text shown in tooltips': 'إدارة نص المساعدة الموضح في خانة تعريف الأدوات',
  'Release notes': 'مذكرات الإصدار',
  'Tooltip ID': 'رمز التعريف في خانة تعريف الأدوات',
  'Default text (English': 'النص الافتراضي (إنجليزي)',
  'Add new entry': 'إضافة مدخل جديدً',
  'Add Help Text': 'إضافة نص مساعدة',
  'Edit Help Text': 'تعديل نص مساعدة',
  'Create a new help text entry.': 'إنشاء مدخل نص مساعدة جديد.',
  'Update existing help text': 'تحديث نص مساعدة حالي',
  'Release note text': 'قم بإصدار نص ملاحظة',
  'Generate from prompt': 'إنشاء من موجه',
  'Tags': 'العلامات',
  'Generate translations': 'إنشاء ترجمات',
  'Generate Help Text': 'إنشاء نص مساعدة',
  'Use a prompt to generate suggested help text': 'استخدم موجهًا لإنشاء نص مساعدة مقترح',
  'Prompt': 'موجّه',
  'Result': 'النتيجة',
  'Generate text': 'إنشاء نص ',
  'Use suggested text': 'استخدام النص المقترح',
  'Select tags': 'حدد العلامات',
  'View and manage translations': 'الاطلاع على الترجمات وإدارتها',
  'The following translations will be updated:': 'سوف يتم تحديث الترجمات التالية:',
  'Title': 'عنوان',
  'No translation available': 'لا توجد ترجمة متاحة',
  'Sections': 'أقسام',
  'Section': 'قسم',
  'Manage release notes sections': 'إدارة أقسام مذكرات الإصدار',
  'Add section': 'إضافة قسم',
  'Manage release notes': 'إدارة مذكرات الإصدار',
  'Create a new release note content item': 'إنشاء بند جديد في محتوى مذكرة إصدار ',
  'Update the selected release note content item': 'تحديث البند المحدد في محتوى مذكرة إصدار',
  'Text': 'نص',
  'Select a section': 'حدد أحد الأقسام',
  'Add release note': 'إضافة مذكرة إصدار',
  'Create a new release note': 'إنشاء مذكرة إصدار جديدة',
  'Content': 'المحتوى',
  'No content added yet': 'لم يتم إضافة أي محتوى حتى الآن',
  'Manage sections': 'إدارة الأقسام',
  'Add content item': 'إضافة بند في المحتوى',
  'Edit content item': 'تعديل بند في المحتوى',
  'Publish date': 'تاريخ النشر',
  'Published': 'تم النشر',
  'Visible to users': 'ظاهر للمستخدمين',
  'Edit release note': 'تعديل مذكرة إصدار',
  'Update the release note details': 'تحديث تفاصيل مذكرة إصدار',
  'Manage tags for help text and release notes': 'إدارة العلامات لنص المساعدة ومذكرات الإصدار',
  'Add tag': 'إضافة علامة',
  'Create a new FYI tag': 'إنشاء علامة جديدة لبند \'لمعلوماتك\'',
  'Edit tag': 'تعديل العلامات',
  'Update the tag name': 'تحديث اسم العلامة',
  'Manage labels': 'إدارة العلامات المميزة',
  'Inactive': 'غير نشط؛',
  'Edit user': 'تعديل في المستخدمين',
  'View logs': 'الاطلاع على السجلات ',
  'Send invitation': 'إرسال دعوة',
  'Feature flags': 'علامات الخصائص',
  'Unconfirmed': 'غير مؤكد',
  'Confirmed': 'مؤكد',
  'Archived': 'تمت أرشفته؛',
  'Compromised': 'ليس في الوضع الأمثل',
  'Reset required': 'إعادة الضبط مطلوبة',
  'Invitation not used': 'لم تُستخدم الدعوة',
  'No invitation sent': 'لم تُرسل أي دعوة',
  'docs': 'المستندات',
  'organization': 'المؤسسة',
  'Configuration': 'تهيئة',
  'Unique ID': 'رمز تعريف فريد',
  'Relays': 'المرحلات',
  'Register number is required.': 'رقم التسجيل مطلوب.',
  'Address is required': 'العنوان مطلوب',
  'Slave address is required': 'العنوان التابع مطلوب',
  'Baudrate is required': 'مُعدل الإرسال مطلوب',
  'You must include at least one relay configuration.': 'يجب عليك تضمين تهيئة مُرحّل واحدة على الأقل.',
  'Please select a relay': 'يُرجى تحديد مّرحّل',
  'Please select a sensor': 'يُرجى تحديد أداة استشعار',
  'Please select a measurement': 'يرجى تحديد مقياس',
  'Mininum value is required': 'القيمة الدنيا مطلوبة',
  'Maximum value is required': 'القيمة القصوى مطلوبة',
  'You must include at least one specification.': 'يجب عليك تضمين مواصفة واحدة على الأقل.',
  'Please provide a value': 'يرجى تقديم قيمة',
  'Only numbers are supported': 'الأرقام فقط مدعومة',
  'Frequency can not be less than one': 'لا يمكن أن تكون قيمة التواتر أقل من واحد',
  'Sleep Cycle (minutes': '',
  'Edit Modbus Configuration': 'تعديل تهيئة بروتوكول مودبس',
  'Configure SIM': '',
  'Device is being moved': 'يجري نقل الجهاز',
  'The device': 'الجهاز',
  'is being moved to another location and will take a few minutes to complete.': 'ينقل إلى موقع آخر وسيستغرق استكمال النقل بضع دقائق.',
  'You will be unable to move the device again for the next 15 minutes.': 'لن تتمكن من نقل الجهاز مرة أخرى خلال الـ 15 دقيقة التالية.',
  'Make sure to physically move the device to the new location.': 'تأكد من نقل الجهاز فعليًا إلى الموقع الجديد.',
  'Move device': 'نقل الجهاز',
  'Move device will be unlocked': 'سيتم إلغاء قفل نقل الجهاز',
  'Select a new location to move the selected device': 'حدد موقعًا جديدًا لنقل الجهاز المحدد',
  'in': 'في؛',
  'is being moved to': 'جارٍ النقل إلى؛',
  'Move Device': 'نقل الجهاز',
  'Confirm move': 'تأكيد النقل؛',
  'Function Code': '',
  'Select the measurements to read from the device.': 'تحديد المقاييس اللازم قراءتها من الجهاز.',
  'Select a measurement': 'تحديد مقياس',
  'The register value must be between 0 and 247.': 'يجب أن تكون قيمة التسجيل بين 0 و247.',
  'Edit the Modbus configuration for this device.': 'تعديل تهيئة بروتوكول المودبس لهذا الجهاز.',
  'Modbus Configuration JSON': 'تعديل صيغة \'JSON\' لتهيئة بروتوكول المودبس',
  'Save Changes': 'حفظ التغييرات',
  'Suspended': '',
  'Soracom': '',
  'SIM Configuration': '',
  'View and edit the SIM configuration for this cellular device.': '',
  'Error fetching SIM details. Our team has been notified.': '',
  'Provider': '',
  'Sim ID': '',
  'Select Status': 'تحديد الحالة؛',
  'Suspending a SIM is irreversible. Proceed with caution.': '',
  'Go Back': 'رجوع؛',
  'There seems to be an issue with the device configuration and it cannot be updated anymore. Please contact support.': 'يبدو أن هناك مشكلة في تهيئة الجهاز ولا يمكن تحديثه بعد الآن. يرجى الاتصال بفريق الدعم.',
  'is a required field.': 'حقل مطلوب.',
  'Latitude must be a number between -90 and 90.': 'يجب أن تكون قيمة خط العرض رقمًا بين -90 و90.',
  'Longitude must be a number between -180 and 180.': 'يجب أن تكون قيمة خط الطول رقمًا بين -180 و180.',
  'Bytesize': 'حجم البايت',
  'Stopbits': 'وحدات بت التوقف',
  'Function Code must be 3 or 4': '',
  'Ping Sensor SIM': '',
  'Success': 'تم بنجاح؛',
  'RTT': '',
  'Yes': '',
  '\'Ping Sensors SIM\'': '',
  'No Greengrass Devices': 'لا توجد أجهزة Greengrass',
  'No Direct Connect Sensors': 'لا توجد مستشعرات  Direct Connect',
  'OTA update started.': 'بدء التحديث عن بعد.',
  'OTA Device Update': 'تحديث الجهاز عن بعد',
  'Core Device Update': 'تحديث الجهاز الأساسي',
  'Destination': 'الوجهة؛',
  'Devices to be updated:': 'الأجهزة المراد تحديثها هي:',
  'This action will update all Greengrass sensors within the device(s': 'سوف يؤدي هذا الإجراء إلى تحديث جميع أدوات الاستشعار \'جرين جراس\' بالجهاز (الأجهزة).',
  'Sensors to be updated:': 'أجهزة الاستشعار المُراد تحديثها هي:',
  'Core Devices for update:': 'الأجهزة الأساسية التي يلزم تحديثها هي:',
  'Submit': 'إرسال؛',
  'OTA Update by Sensor': 'التحديث عن بعد حسب جهاز الاستشعار',
  'OTA Update by Core Device': 'التحديث عن بعد حسب الجهاز الأساسي',
  'Request NVS Dump': 'طلب تفريغ \'NVS\'',
  'Request Device Status': 'طلب حالة الجهاز',
  'Request SIM Diagnostic Data': '',
  'Request SIM Diagnostic Ping': '',
  'Request Sensor Diagnostic Data': '',
  'Restart Sensor': '',
  'Communications Board': 'لوحة الاتصالات',
  'Select all sensors': 'تحديد كل أجهزة الاستشعار',
  'Select only the device': 'تحديد الجهاز فقط',
  'Select all sensors and device': 'تحديد كل أجهزة الاستشعار والجهاز',
  'User is required': 'المستخدم مطلوب؛',
  'Selected zone to move': 'منطقة محددة للنقل؛',
  'Select a new location to move the selected location and their children to.': 'تحديد موقع جديد لنقل الموقع المحدد وأفرعه إليه.',
  'This': 'هذا؛',
  'Move location': 'نقل الموقع؛',
  'Loading': 'جارٍ التحميل؛',
  'Search': 'بحث؛',
  'Check your mailbox': 'قم بمراجعة صندوق بريدك',
  'A verification code was sent to the email address you provided. Enter the code into the field below to activate your account. Do not leave this page until you have completed this step.': 'تم إرسال رمز التحقق إلى عنوان البريد الإلكتروني الذي قدمته. قم بإدخال الرمز في الحقل أدناه لتفعيل حسابك. لا تغادر هذه الصفحة حتى تنتهي من هذه الخطوة.',
  'Verification code': 'رمز التحقق',
  'Continue': 'متابعة؛',
  '\'By continuing you agree to SecondSky Datas \'': '',
  'Privacy Policy': 'لكوريتكس \'Coretex\'',
  'The provided device ID is invalid.': 'الرمز التعريفي للجهاز المقدم غير صالح.',
  'You need a valid device ID to create an account. Try scanning the QR code on your device again, or contact your supplier.': 'تحتاج إلى رمز تعريفي صالح للجهاز من أجل إنشاء حساب حاول إجراء مسح ضوئي للكود \'QR\' الذي في جهازك مرة أخرى، أو قم بالتواصل مع مردك.',
  'Device is already enrolled.': 'الجهاز مسجل بالفعل.',
  'This device is already enrolled on SecondSky Data. You can sign in to see enrolled devices. If you do not have an account, ask a user from your organization to invite you to the platform.': '',
  'Sign in or create a new account to enroll your device.': 'سجّل الدخول أو قم بإنشاء حساب جديد لتسجيل جهازك.',
  'Confirm password': 'تأكيد كلمة السر',
  'Phone number': 'رقم الهاتف',
  'This will create a new organization. To join an existing organization, you must be invited by a user in that organization.': 'سينشئ ذلك مؤسسة جديدة. للانضمام لمؤسسة قائمة، يجب أن يتم دعوتك من قبل أحد المستخدمين في تلك المؤسسة.',
  'Sign in to SecondSky Data': '',
  'Username or email': 'اسم المستخدم أو البريد الإلكتروني',
  'Sign In': 'تسجيل الدخول؛',
  'An error ocurred': 'حدث خطأ؛',
  'Forgot Password?': 'هل نسيت كلمة المرور؟؛',
  'Forgot password': 'نسيت كلمة المرور؛',
  'New password': 'كلمة مرور جديدة',
  'Re-enter new password': 'أعد إدخال كلمة المرور الجديدة',
  'Your password must contain at least eight characters including one symbol, one capital letter and one number.': 'يجب أن تحتوي كلمة المرور الخاصة بك على ثمانية أحرف على الأقل، تشمل رمز واحد وحرف كبير واحد ورقم واحد على الأقل.',
  'Verify': 'تأكيد الصحة؛',
  'Change password': 'تغيير كلمة المرور؛',
  'The email address associated with your account is not yet verified:': 'لم يتم التحقق بعد من عنوان البريد الإلكتروني المرتبط بحسابك:',
  'A verification code has been sent to your email address.': 'تم إرسال رمز التحقق إلى عنوان بريدك الإلكتروني.',
  'Password reset complete.': 'اكتملت عملية إعادة تعيين كلمة المرور.؛',
  'Back to Sign In': 'عودة إلى تسجيل الدخول؛',
  'Skip': 'تخطي؛',
  'Verify your account': 'تأكيد صحة حسابك؛',
  'Multi-factor authentication': 'مصادقة متعددة العوامل',
  'Open your authenticator app to view the authentication code, then enter the code into the field below.': 'قم بفتح تطبيق الإدارة الخاص بك للاطلاع على كود المصادقة، ثم قم بإدخال الكود في الخانة المبينة أدناه.',
  'Passwords do not match': 'كلمات المرور غير متطابقة؛',
  'User not found': 'لم يتم العثور على المستخدم',
  'Please first use the temporary password you received email.': 'يُرجى أولاً استخدام كلمة المرور المؤقتة التي حصلت عليها عبر البريد الإلكتروني.؛',
  'You have entered an invalid username/email or password': 'لقد أدخلت اسم مستخدم/بريد إلكتروني أو كلمة مرور غير صالحة',
  'Don\\t have an account?': 'هل ليس لديك حساب؟',
  'Register here.': 'سجل هنا.',
  'Create an account': 'إنشاء حساب',
  'Enter user details': 'إدخال تفاصيل المستخدم',
  'Verify email address': 'تحقيق عنوان البريد الإلكتروني',
  'Calibration History': 'سجل المعايرة',
  'History': 'السجل',
  'No Data': 'لا توجد بيانات؛',
  'Select columns': 'حدد الأعمدة',
  'Columns': 'الأعمدة',
  'Show Graph': 'عرض الرسم البياني',
  'Show Table': 'عرض الجدول',
  'Reset': 'إعادة الضبط',
  'Search...': 'بحث...',
  'Calibrate': 'معايرة؛',
  'Successfully calibrated': 'تمت المعيرة بنجاح',
  'Enter': 'أدخل',
  'Using the reference': 'استخدام المرجع',
  'Enter the value into the text box in the adjacent form.': 'أدخل القيمة في مربع النص في النموذج المجاور.؛',
  'Press Calibrate button.': 'اضغط على زر \'معايرة\'.؛',
  'Calibration Success': 'تمت المعايرة بنجاح؛',
  'Calibration Procedure': 'إجراء المعايرة؛',
  'Back': 'رجوع؛',
  'Min:': 'الحد الأدنى:؛',
  'Max:': 'الحد الأقصى:؛',
  'Humidity': 'الرطوبة؛',
  'EC': 'إمكانية التوصيل الكهربي؛',
  'factor': 'العامل؛',
  'reference value': 'القيمة المرجعية؛',
  'Calibrate by factor': 'معايرة حسب العامل؛',
  'Calibrate by reference value': 'معايرة حسب القيمة المرجعية؛',
  'Refetch history': 'تنشيط التاريخ',
  'No calibration data': 'لا توجد بيانات معايرة',
  'Cap Coefficients': 'معاملات الغطاء',
  'User Coefficients': 'معاملات المستخدم',
  'Enter Salinity value': 'إدخال قيمة الملوحة',
  'Pressure': 'الضغط؛',
  'Enter Pressure value': 'إدخال قيمة الضغط',
  'Enter coefficient': 'إدخال المعامل',
  '2 point calibration': 'معايرة النقطتين',
  'low point': 'نقطة منخفضة',
  'high point': 'نقطة عالية',
  'Calibrate ph1': 'معايرة المرحلة 1',
  'Calibrate ph2': 'معايرة المرحلة 2',
  '2 point source calibration': 'معايرة مصدر 2 نقطة',
  'Factor source calibration': 'معايرة عامل المصدر',
  'Use Previous Generation': 'استخدام الجيل السابق',
  'Download Datasets': 'تنزيل مجموعات البيانات',
  'Download all dashboard line chart datasets as CSV': 'تنزيل كل مجموعات بيانات المخطط الخطي للوحة المعلومات بتنسيق \'CSV\'',
  'Zipping data... Do not close or refresh the page.': 'ضغط البيانات... لا تغلق الصفحة أو تنشطها.',
  'You are about to download a zip file containing': 'أنت على وشك تنزيل ملف مضغوط يحتوي على',
  'line chart datasets.': 'مجموعات بيانات مخطط بياني خطي.',
  'This may take a while depending on the number of datasets.': 'قد يستغرق هذا بعض الوقت اعتمادًا على عدد مجموعات البيانات.',
  'Download': 'تنزيل؛',
  'Layout': 'المخطط العام',
  'Select layout': 'حدد مخطط عام',
  'Layout tools': 'أدوات إعداد المخطط العام',
  'Location layouts': 'مخططات الموقع',
  'Measurement layouts': 'توزيع المقاييس',
  'Reset to': 'إعادة الإعداد إلى',
  'default layout': 'المخطط الافتراضي',
  'Create layout from current': 'إنشاء مخطط من البيانات الحالية',
  'Clear current layout': 'حذف المخطط الحالي',
  'Real-time': 'في الوقت الفعلي',
  'Average Value (Last 24 Hours - Hourly': 'القيمة المتوسطة (آخر 24 ساعة - كل ساعة',
  'Dataset Line (Last 24 Hours - Hourly': 'خط مجموعة البيانات (آخر 24 ساعة - كل ساعة',
  'Dataset Line (Last 7 Days - Daily': 'خط مجموعة البيانات (آخر 7 أيام - يوميًا',
  'Dataset Line (Last 7 Days - Hourly': 'خط مجموعة البيانات (آخر 7 أيام - كل ساعة',
  'is above': 'فوق',
  'is below': 'أدنى من',
  'Exceeded on': 'تجاوز',
  'threshold is': 'العتبة هي',
  'Snooze Alert': 'تنبيه الغفوة',
  'Select Sensor': 'تحديد مستشعر؛',
  'someSelectedSensorsSnoozed ? Cancel : Snooze': 'هل تم تأجيل بعض أجهزة الاستشعار المحددة؟ إلغاء: غفوة',
  'is not sending data for:': 'لا يتم إرسال البيانات لما يلي:',
  'Delete Notification': 'حذف الإخطار',
  'Above on': 'فوق',
  'Below on': 'أدنى',
  'Delete notification for': 'حذف الإشعار لـ؛',
  'This action cannot be undone.': 'لا يمكن التراجع عن هذا الإجراء.؛',
  'is below threshold': 'أقل من العتبة؛',
  'is above threshold': 'أكبر من العتبة؛',
  'for': 'من أجل؛',
  'notification': 'إشعار؛',
  'caution': 'تنبيه؛',
  'warning': 'تحذير؛',
  'Snooze': 'تأجيل؛',
  'Snoozed': 'مؤجَّل؛',
  'min': 'دقيقة؛',
  'mins': 'دقائق؛',
  'Alert in': 'تنبيه في',
  'Select a widget': 'اختر الأداة',
  'Miscellaneous': 'منوعات؛',
  'Plan': 'الخطة؛',
  'Harvest': 'عملية الحصاد؛',
  'Live': 'مباشر؛',
  'Total Dissolved Solids': 'إجمالي المواد الصلبة الذائبة',
  'Soil Phosphorus Content': 'محتوى التربة من الفوسفور',
  'Soil Potassium Content': 'محتوى التربة من البوتاسيوم',
  'Soil Nitrogen Content': 'محتوى التربة من النيتروجين',
  'Battery SoC': 'شحنة البطارية',
  'Select a time format': 'حدد نسق الوقت',
  '24h': '24 ساعة',
  '12h': '12 ساعة',
  'Not applicable': '',
  'No crops': 'لا توجد محاصيل؛',
  'Crops': 'المحاصيل؛',
  'Loading produces': 'تحميل المنتجات',
  'Select a query is required': 'حدد استعلامًا مطلوب',
  'Select a chart is required': 'حدد رسمًا بيانيًا مطلوب',
  'Select a range is required': 'حدد نطاقًا مطلوب',
  'Select a produce is required': 'حدد منتجًا مطلوب',
  'Select an interval is required': 'حدد فاصلاً زمنيًا مطلوب',
  'Select a value': 'حدد قيمة',
  'Kg': 'كلغ',
  'Produce': 'المحصول؛',
  'Total Harvested Produce': 'إجمالي المحاصيل التي تم حصادها؛',
  'Total Produce': 'إجمالي المحاصيل؛',
  'Top 10 Harvested Varieties': 'أفضل 10 مجموعات متنوعة تم حصادها؛',
  'Top 10 Varieties': 'أفضل 10 مجموعات متنوعة؛',
  'Planned vs harvested varieties': 'المجموعات المتنوعة المخطط لها مقابل ما تم حصاده؛',
  'Planned vs Harvested': 'ما تم التخطيط له مقابل ما تم حصاده؛',
  'Current Crops': '',
  'Daily Energy Consumption': 'استهلاك الطاقة اليومي',
  'Energy KW': 'طاقة بالكيلوواط',
  'No data': 'لا توجد بيانات',
  'Subtitle': 'عنوان فرعي',
  '+1 hour': 'أكثر من ساعة',
  'Average': 'المتوسط؛',
  'Dataset': 'مجموعة البيانات',
  'Value': 'القيمة؛',
  'Line': 'خط',
  'Scatterplot': 'مخطط التشتت',
  'Pie': 'مخطط دائري',
  'Bar': 'مخطط شريطي',
  'Matrix': 'مصفوفة',
  'Last hour': 'الساعة الأخيرة',
  'Today': 'اليوم',
  'Last 24 hours': '24 ساعة الماضية',
  'Last 7 days': '7 أيام الماضية',
  'Last 30 days': '30 يومًا الماضية',
  'Last 3 months': '3 أشهر الماضية',
  'Current year': 'العام الحالي',
  'Last year': 'العام الماضي',
  'Use range interval': 'استخدم الفاصل الزمني',
  'Minutely': 'كل دقيقة؛',
  'Hourly': 'كل ساعة؛',
  'Daily': 'كل يوم؛',
  'Weekly': 'أسبوعيًا',
  'Monthly': 'شهريًا',
  'Edit Widget': 'عدّل الأداة',
  'Configure the widget settings.': 'قم بتهيئة إعدادات الأداة.',
  'Edit the widget settings': 'عدّل إعدادات الأداة',
  'Select a query': 'حدد استعلامًا',
  'Select a chart': 'حدد مخططًا بيانيًا',
  'Select a range': 'حدد نطاقًا',
  'Select an interval': 'تحديد فاصل زمني؛',
  'Select a produce': 'حدد منتجًا',
  'depthLabels[index]': '',
  'Moisture': '',
  'ºF': '',
  'Next 7 days': '7 أيام القادمة',
  'The device you are looking for does not exist.': 'الجهاز الذي تبحث عنه غير موجود.',
  'The': 'الـ',
  'Toolbox': 'مربع الأدوات؛',
  'Choose a toolbox item': 'اختر عنصرًا من صندوق الأدوات',
  'Add New Widget': 'أضف أداة جديدة',
  'Select a device': 'حدد جهازًا',
  'Now': 'الآن',
  'Live data': '',
  'No data available': 'لا توجد بيانات متاحة',
  'Weather forecast': '',
  'This week': 'هذا الأسبوع',
  'Select a location': 'اختر موقعا',
  '24 hours': '24 ساعة',
  '7 days': '7 أيام',
  '30 days': '30 يومًا',
  'Fetching graph data': 'إحضار بيانات الرسم البياني',
  'No data for:': 'لا توجد بيانات عن:',
  'Current': 'حالي',
  'Sensors': 'مستشعرات؛',
  'Select': 'تحديد؛',
  'sensor': 'جهاز الاستشعار',
  'Long soil sensor': '',
  '10cm': '',
  '20cm': '',
  '30cm': '',
  '40cm': '',
  '50cm': '',
  '60cm': '',
  '70cm': '',
  '80cm': '',
  '90cm': '',
  'Simple': '',
  'Custom': '',
  'Data API keys': 'مفاتيح واجهة برمجة التطبيقات للبيانات',
  'API key': 'مفتاح واجهة برمجة التطبيقات',
  'Organization name': 'اسم المؤسسة',
  'Organization ID': 'معرف المؤسسة',
  'Please contact support': 'يرجى الاتصال بالدعم',
  'Loading...': 'التحميل جارٍ...',
  'Data API Reference': 'مرجع بيانات الوسط البيني لبرمجة التطبيقات',
  'delete': 'حذف',
  'Regenerate': 'إعادة توليد',
  'Regenerate API key': 'إعادة إنشاء مفتاح الوسط البيني لبرمجة التطبيقات',
  'To proceed, enter': 'للمواصلة، أدخل',
  'below, then click': 'أدناه، ثم أنقر على',
  'Generate new API key': 'إنشاء مفتاح جديد للوسط البيني لبرمجة التطبيقات',
  'Create a new API key for': 'إنشاء مفتاح جديد للوسط البيني لبرمجة التطبيقات لما يلي',
  'Type the word': 'اكتب الكلمة',
  'Warning': 'تحذير',
  'Regenerating the API key will invalidate the old key. Any applications currently using the old key will break. This action cannot be undone.': 'سوف يؤدي إعادة توليد مفتاح الوسط البيني لبرمجة التطبيقات إلى إلغاء المفتاح القديم. أي تطبيقات تستخدم المفتاح القديم حاليًا سوف تتعطل. لا يمكن الرجوع في هذا الإجراء.',
  'Check the API Reference for more information on how to use the Data API': 'قم بفحص مرجع الوسط البيني لبرمجة التطبيقات للحصول على مزيد من المعلومات حول كيفية استخدام بيانات الوسط البيني لبرمجة التطبيقات',
  'Something went wrong. Please try again.': 'حدث خطأ ما. يُرجى إعادة المحاولة.',
  'Export Chart': 'مخطط التصدير',
  'Customize your graph export dimensions': 'خصيص أبعاد مخطط التصدير البياني الخاص بك',
  'Exporting chart... Do not close or refresh the page.': 'مخطط التصدير... لا تغلق الصفحة أو تنشطها.',
  'Width': 'العرض',
  'Height': 'الارتفاع',
  'Export': 'التصدير',
  'Width must be at least 512px': 'يجب أن يكون العرض 512 بكسل على الأقل',
  'Width must be less than 1920px': 'يجب أن يكون العرض أقل من 1920 بكسل',
  'Height must be at least 288px': 'يجب أن يكون الارتفاع 288 بكسل على الأقل',
  'Height must be less than 1080px': 'يجب أن يكون الارتفاع أقل من 1080 بكسل',
  'Date Range': 'نطاق التاريخ',
  'Add Date Filter': 'إضافة مرشح التواريخ',
  'Add Location Filter': 'إضافة مرشح المواقع',
  'Duplicate Last Location Filter': '',
  'Preset Time Range': 'نطاق وقت محدد مسبقًا',
  'Start Date': 'تاريخ البدء',
  'End Date': 'تاريخ الانتهاء',
  'Graph Interval': 'فاصل المخطط البياني',
  'Select Organization': 'حدد المؤسسة',
  'Time Range & Interval': 'النطاق الزمني والفاصل الزمني',
  'Location of Interest': 'الموقع محل الاهتمام',
  'Configure Databoard': 'تهيئة لوحة البيانات',
  'Select Measurements': 'تحديد المقاييس',
  'The date fields time values are set in the time zone specified above.': 'تعد القيم الزمنية في حقول التاريخ في المنطقة الزمنية المحددة أعلاه.',
  'Databoard': 'لوحة البيانات',
  'Please select a time range, measurements and locations to view the associated data.': 'يرجى تحديد نطاق زمني، ومقاييس ومواقع، للاطلاع على البيانات المرفقة.',
  'Please wait while we fetch the requested data': 'يرجى الانتظار حتى نحضر البيانات المطلوبة',
  'Select Measurement(s': 'حدد القياس (القياسات)',
  '1-4': '',
  'Select measurements': 'تحديد مقاييس؛',
  'Select a zone': 'تحديد منطقة؛',
  'Download Data': '01-Apr',
  'Download graph': 'تنزيل البيانات',
  'Fetch Data': 'تنزيل المخطط البياني',
  'Date range': 'نطاق التاريخ؛',
  'Historical Data': 'بيانات تاريخية؛',
  'Custom Range': 'نطاق مخصص',
  'Yesterday': 'أمس',
  'Last month': 'الشهر الماضي',
  'This year': 'هذا العام',
  'Last 12 months': '12 أشهر الماضية',
  'Too much data to display': 'توجد الكثير من البيانات لعرضها',
  'Compare data by:': 'توفير البيانات',
  'Date Ranges': 'مقارنة البيانات حسب:',
  'Locations': 'نطاقات التاريخ',
  'Add new date range': 'المواقع',
  'You cannot compare a location to itself, please remove the duplicate locations.': 'إضافة نطاق تاريخ جديد',
  'You cannot compare a date range to itself, please remove the duplicate date ranges.': 'لا يمكنك مقارنة موقع بنفسه، يرجى إزالة المواقع المكررة.',
  'The selected locations do not have any common measurements.': 'لا يمكنك مقارنة نطاق زمني بنفسه، يرجة إزالة النطاقات الزمنية المكررة.',
  'Minute': '',
  'Hour': '',
  'Day': '',
  'Week': '',
  'Month': '',
  'Device with the provided ID does not exist or you do not have permission to view it. Please contact support.': 'لا تحتوي المواقع المحددة على أي مقاييس مشتركة.',
  'is enrolled': 'لا يوجد جهاز بالرمزالتعريفي المقدم، أو أنه ليس لديك تصريح بالاطلاع عليه. يرجى الاتصال بفريق الدعم.',
  'The device must be connected and powered on for you to see data.': 'مُسجّل',
  'Go to device dashboard': 'يجب توصيل الجهاز وتشغيله حتى تتمكن من رؤية البيانات.',
  'Update coordinates': 'انتقل إلى لوحة معلومات الجهاز',
  'Made a mistake when enrolling the device? Unenroll it and start the process again.': 'تحديث الإحداثيات',
  'Unenroll device': 'هل أخطأت عند تسجيل الجهاز؟ قم بإلغاء تسجيله وابدأ العملية مرة أخرى.',
  'Create a new': 'إلغاء تسجيل الجهاز',
  'Choose a': 'إنشاء جديد',
  'This is the physical location of your operation, pinpointed by a unique geographic coordinate. This coordinate may represent an expansive area that includes a variety of areas and sub-areas.': 'اختر',
  'Select an existing': 'هذا هو الموقع الفعلي لعمليتك، محدد بدقة بواسطة إحداثيات جغرافية فريدة. قد تمثل هذه الإحداثيات منطقة واسعة تتضمن مجموعة متنوعة من المناطق والمناطق الفرعية.',
  'You do not have permission to create': 'حدد عنصر موجود',
  'Please talk to your administrator.': 'ليس لديك الإذن بالإنشاء',
  'Device ID': 'يرجى التحدث مع المدير المسؤول.',
  'Display name': 'الرمز التعريفي للجهاز',
  'Back to room': 'اسم العرض',
  'Enroll your device': 'العودة إلى الغرفة',
  'Confirm the details for your device below. Note that the device ID can not be edited. You can go back to the previous steps to edit the location details.': 'سجل جهازك',
  'Your current coordinates': 'قم بتأكيد التفاصيل الخاصة بجهازك أدناه. لاحظ أنه لا يمكن تعديل الرمز التعريفي للجهاز. يمكنك العودة إلى الخطوات السابقة لتعديل تفاصيل الموقع.',
  'Use current coordinates': 'إحداثياتك الحالية',
  'Saved coordinates': 'استخدم الإحداثيات الحالية',
  'Update device coordinates': 'الإحداثيات المحفوظة',
  'Use your current location to set updated coordinates for ': 'تحديث إحداثيات الجهاز',
  'Stand as close to the device as you can for the most accurate results.': 'استخدم مقعك الحالي لوضع إحداثيات محدثة ',
  'This is a designated area inside of a': 'قف بالقرب من الجهاز بقدر الإمكان للحصول على أدق النتائج.',
  'You do not have permission to create zones. Please talk to your administrator.': 'هذه منطقة مخصصة داخل ',
  'Back to': 'ليس لديك الإذن بإنشاء نطاقات. يرجى التحدث مع المسؤول الخاص بك.',
  'Device ID is not valid. Try scanning the device again or contact your supplier.': 'رجوع إلى',
  'Device not found': '',
  'Location not found': '',
  'Please enter a valid ID': '',
  'Find Dashboard': '',
  'Select either Device or Location and enter its ID': '',
  'Search Type': '',
  'Missing data': '',
  'Not in use': '',
  'Sorry, we couldnt find what you were looking for!': 'معذرة، لم نتمكن من العثور على ما كنت تبحث عنه!؛',
  'A name is required.': '',
  'All measurements are required.': '',
  'All bounds are required.': '',
  'Manage Produce Threshold': '',
  'Save Produce Threshold': '',
  'Add Measurement': '',
  'No measurements': '',
  'Lower Bounds': '',
  'Upper Bounds': '',
  'Stress': '',
  'Bad': '',
  'Good': '',
  'Produce threshold created successfully.': '',
  'Produce threshold updated successfully.': '',
  'Please note that temperatures are stored in Celsius and may change when converted.': '',
  'Add Produce Threshold': '',
  'No entries': 'لا توجد إدخالات؛',
  'Priority is invalid.': '',
  'At least one condition is required.': '',
  'All condition fields are required.': '',
  'A message is required.': '',
  'Manage Produce Threshold Message': '',
  'Save Produce Threshold Message': '',
  'Priority': '',
  'Conditions': 'الشروط؛',
  'Add Condition': '',
  'No conditions have been set': '',
  'Condition': '',
  'Expression': '',
  'Bound': '',
  'Please select': 'يُرجى التحديد؛',
  'Produce threshold message created successfully.': '',
  'Produce threshold message updated successfully.': '',
  'Produce threshold message deleted successfully.': '',
  'Add Produce Threshold Message': '',
  'No messages': '',
  'More than or equal to': '',
  'Less than or equal to': '',
  'Stress Lower': '',
  'Bad Lower': '',
  'Good Lower': '',
  'Good Upper': '',
  'Bad Upper': '',
  'Stress Upper': '',
  'High': '',
  'Medium': '',
  'Low': '',
  'Produce Thresholds': '',
  'Produce Threshold Messages': '',
  'An organization is required.': 'مطلوب تحديد مؤسسة.؛',
  'Name is required.': 'الاسم مطلوب.؛',
  'A country must be selected.': 'يجب تحديد بلد.؛',
  'A region must be selected.': 'يجب تحديد منطقة.؛',
  'A city must be selected.': 'يجب تحديد مدينة.؛',
  'Manage Area': 'إدارة مساحة؛',
  'Save Area': 'حفظ المساحة؛',
  'Country': 'البلد؛',
  'Region': 'المنطقة؛',
  'City': 'المدينة؛',
  'Manage Areas': 'إدارة المساحات؛',
  'Add Area': 'إضافة مساحة؛',
  'No regions.': 'لا توجد مناطق.؛',
  'Area': 'المساحة؛',
  'Manage City': 'إدارة مدينة؛',
  'Save City': 'حفظ المدينة؛',
  'Manage Cities': 'إدارة المدن؛',
  'Add City': 'إضافة مدينة؛',
  'No cities.': 'لا توجد مدن.؛',
  'Areas': 'مساحات؛',
  'ISO Code a required field.': 'كود ISO حقل مطلوب.؛',
  'Dialing Code a required field.': 'رمز الاتصال حقل مطلوب.؛',
  'A Currency must be selected.': 'يجب تحديد عملة.؛',
  'Manage Country': 'إدارة البلد؛',
  'Save Country': 'حفظ البلد؛',
  'Currency': 'العملة؛',
  'Dialing Code': 'رمز الاتصال؛',
  'ISO Code': 'كود ISO؛',
  'Manage Countries': 'إدارة البُلدان؛',
  'Add Country': 'إضافة بلد؛',
  'Regions': 'المناطق؛',
  'Code is required.': 'مطلوب تحديد الرمز.؛',
  'USD Rate is required.': 'السعر بالدولار الأمريكي مطلوب.؛',
  'Manage Currency': 'إدارة العملة؛',
  'Save Currency': 'حفظ العملة؛',
  'USD Rate': 'السعر بالدولار الأمريكي؛',
  'USD': 'دولار أمريكي؛',
  'Manage Currencies': 'إدارة العملات؛',
  'Add Currency': 'إضافة عملة؛',
  'Manage Grades': 'إدارة الدرجات؛',
  'Save Grade': 'حفظ الدرجة؛',
  'Add Grade': 'إضافة درجة؛',
  'Grade': 'الدرجة؛',
  'A category is required.': 'الفئة مطلوبة.؛',
  'A status is required.': 'الحالة مطلوبة.؛',
  'Manage Package Unit': 'إدارة وحدة التعبئة؛',
  'Save Package Unit': 'حفظ وحدة التعبئة؛',
  'Manage Package Units': 'إدارة وحدات التعبئة؛',
  'Add Package Unit': 'إضافة وحدة تعبئة؛',
  'No package units': 'لا توجد وحدات تعبئة؛',
  'Category': 'الفئة؛',
  'Please Select': 'يُرجى التحديد؛',
  'g': 'ز؛',
  'Available': 'متوفر؛',
  'Box': 'صندوق؛',
  'Bag': 'كيس؛',
  'Felin': 'فلين؛',
  'Clamshell': 'علبة صدفية؛',
  'Cup': 'كأس؛',
  'Tray': 'طينية؛',
  'Lid': 'غطاء؛',
  'Pack': 'عبوة؛',
  'Carton': 'صندوق من الكرتون؛',
  'Sleeve': 'تغليف ببلاستيك لف؛',
  'Sticker': 'ملصق؛',
  'All Categories': 'كل الفئات؛',
  'Any Status': 'أي حالة؛',
  'Manage Payment Method': 'إدارة طريقة الدفع؛',
  'Save Payment Method': 'حفظ طريقة الدفع؛',
  'Manage Payment Methods': 'إدارة طرق الدفع؛',
  'Add Payment Method': 'إضافة طريقة دفع؛',
  'Manage Payment Terms': 'إدارة شروط الدفع؛',
  'Save Payment Terms': 'حفظ شروط الدفع؛',
  'Termsheet': 'ورقة الشروط؛',
  'Add Payment Terms': 'إضافة شروط الدفع؛',
  'Term Sheet': 'ورقة الشروط؛',
  'No term sheet': 'لا توجد ورقة شروط؛',
  'Please provide a code.': 'يُرجى توفير رمز.؛',
  'Manage Produce': 'إدارة المحصول؛',
  'Save Produce': 'حفظ المحصول؛',
  'Default Threshold Template': '',
  'Add Produce': 'إضافة محصول؛',
  'Varieties': 'مجموعات متنوعة؛',
  'A Country must be selected.': 'يجب تحديد بلد.؛',
  'Manage Region': 'إدارة منطقة؛',
  'Save Region': 'حفظ المنطقة؛',
  'Manage Regions': 'إدارة مناطق؛',
  'Add Region': 'إضافة منطقة؛',
  'Cities': 'المدن؛',
  'An organization is required field.': 'تحديد المؤسسة حقل مطلوب.؛',
  'A storage facility must be selected.': 'يجب تحديد منشأة تخزين.؛',
  'Manage Shipping Method': 'إدارة طريقة الشحن؛',
  'Save Shipping Method': 'حفظ طريقة الشحن؛',
  'Storage Facility': 'منشأة التخزين؛',
  'Manage Shipping Methods': 'إدارة طرق الشحن؛',
  'Add Shipping Method': 'إضافة طريقة شحن؛',
  'At least one store type is required.': 'مطلوب نوع مخزن واحد على الأقل.؛',
  'Manage Storage Facility': 'إدارة منشأة التخزين؛',
  'Save Storage Facility': 'حفظ منشأة التخزين؛',
  'Store Types': 'أنواع المخازن؛',
  'Cold Storage (Produce': 'مخزن تبريد (محصول؛',
  'General Store (Packaging': 'مخزن عام (تعبئة؛',
  'Chemical Store': 'مخزن كيميائي؛',
  'Fertilizer Store': 'مخزن سماد؛',
  'Refrigerated Seed Store': 'مخزن بذور مبردة؛',
  'Maintenance Supplies': 'مستلزمات صيانة؛',
  'Flammable Caged Storage': 'تخزين في قفص قابل للاشتعال؛',
  'Manage Storage Facilities': 'إدارة منشآت التخزين؛',
  'Add Storage Facility': 'إضافة منشأة تخزين؛',
  'Shipping Methods': 'طرق الشحن؛',
  'A Produce must be selected.': 'يجب تحديد محصول.؛',
  'Manage Variety': 'إدارة مجموعة متنوعة؛',
  'Save Variety': 'حفظ المجموعة المتنوعة؛',
  'Manage Varieties': 'إدارة مجموعات متنوعة؛',
  'Add Variety': 'إضافة مجموعة متنوعة؛',
  'Variety': 'المجموعة المتنوعة؛',
  'Grades': 'الدرجات؛',
  'Countries': 'البلدان؛',
  'Currencies': 'العملات؛',
  'The onboarding date cannot be set in the future.': 'لا يمكن تحديد تاريخ الإضافة في المستقبل.؛',
  'An onboarding date is required.': 'تاريخ الإضافة مطلوب.؛',
  'Contact name is required.': 'اسم جهة الاتصال مطلوب.؛',
  'Contact number is required.': 'رقم جهة الاتصال مطلوب.؛',
  'Contact email is required.': 'البريد الإلكتروني لجهة الاتصال مطلوب.؛',
  'A customer must be selected.': 'يجب تحديد عميل.؛',
  'An area must be selected.': 'يجب تحديد مساحة.؛',
  'A currency must be selected.': 'يجب تحديد عملة.؛',
  'A payment method must be selected.': 'يجب تحديد طريقة دفع.؛',
  'A payment term must be selected.': 'يجب تحديد شرط دفع.؛',
  'Manage Branches': 'إدارة الفروع؛',
  'Create Branch': 'إنشاء فرع؛',
  'Create New Branch': 'إنشاء فرع جديد؛',
  'Update Existing Branch': 'تحديث فرع موجود؛',
  'Save Branch': 'حفظ الفرع؛',
  'Company': 'الشركة؛',
  'Contact': 'جهة الاتصال؛',
  'No branches': 'لا توجد فروع؛',
  'Closed': 'مغلق؛',
  'Banned': 'محظور؛',
  'Branch': 'الفرع؛',
  'Customer': 'العميل؛',
  'Onboarding Date': 'تاريخ الإضافة؛',
  'Contact Name': 'اسم جهة الاتصال؛',
  'Contact Number': 'رقم جهة الاتصال؛',
  'Contact Email': 'البريد الإلكتروني لجهة الاتصال؛',
  'Payment': 'الدفع؛',
  'Payment Method': 'طريقة الدفع؛',
  'Payment Terms': 'شروط الدفع؛',
  'Repeated': 'الرمز التعريفي للجهاز غير صالح. حاول مسح الجهاز مرة أخرى أو اتصل بالمورّد لديك.',
  'All Statuses': 'معاد',
  'Fetch': 'أحضر',
  'GMT': 'كل الأوضاع',
  'Produce is required.': 'المحصول مطلوب.؛',
  'A variety is required.': 'المجموعة المتنوعة مطلوبة.؛',
  'Planting area must be at least 1m2.': 'يجب ألا تقل المساحة المزروعة عن 1 متر مربع.؛',
  'Seed quantity cannot be less than 1.': 'لا يمكن أن تقل كمية البذور عن 1.؛',
  'Manage crops': 'توقيت جرينتش',
  'Create crop': 'إدارة المحاصيل',
  'Create New Crop': 'إنشاء محصول جديد؛',
  'Update Existing Crop': 'تحديث محصول موجود؛',
  'Save crop': 'إنشاء محصول',
  'Crop': 'المحصول؛',
  'Date Created': 'تاريخ الإنشاء؛',
  'Date Updated': 'تاريخ التحديث؛',
  'Recommendations Template': '',
  'Volume': 'الحجم؛',
  'Planting Area': 'المساحة المزروعة؛',
  'Planting Area (m2': 'المساحة المزروعة (متر مربع؛',
  'Growth Medium': 'وسط النمو؛',
  'Hydration Method': 'طريقة إضافة المياه؛',
  'Quantity of Seeds': 'كمية البذور؛',
  'seeds': 'البذور؛',
  'Estimated Sow Date': 'تاريخ الزراعة التقديري؛',
  'Date Sowed': 'تاريخ الزراعة؛',
  'Estimated Transplant Date': 'تاريخ الشتل التقديري؛',
  'Date Transplanted': 'تاريخ الشتل؛',
  'Estimated Harvest Start Date': 'تاريخ بدء الحصاد التقديري؛',
  'Harvest Start Date': 'تاريخ بدء الحصاد؛',
  'Estimated Harvest End Date': 'تاريخ نهاية الحصاد التقديري؛',
  'Harvest End Date': 'تاريخ نهاية الحصاد؛',
  'Schedule': 'الجدول الزمني؛',
  'Planned': 'مُخطط؛',
  'Sowed': 'مزروع؛',
  'Transplanted': 'مشتول؛',
  'Harvesting': 'جارٍ حصاده؛',
  'Harvested': 'تم حصاده؛',
  'Failed': 'فشل؛',
  'Canceled': 'تم الإلغاء؛',
  'Perlite': 'بيرلايت؛',
  'Vermiculite': 'فيرميكولايت؛',
  'Volcanic Rock': 'صخر بركاني؛',
  'Rockwool/Stonewool': 'صوف صخري/صوف حجري؛',
  'Soil': '',
  'Coconut Coir': 'ليف جوز الهند؛',
  'Clay Pellets': 'كرات طين؛',
  'Sand/Gravel/Sawdust': 'رمال/حصى/نشارة خشب؛',
  'Peat Moss': 'طحلب الخث؛',
  'Deep Water Culture': 'الزراعة في المياه العميقة؛',
  'Dutch Bucket': 'دلو هولندي؛',
  'Gutter System': 'نظام مزراب؛',
  'Nutrient Film Technique': 'تقنية الغشاء المغذي؛',
  'Aeroponics': 'أيروبونيك؛',
  'Drip System': 'نظام التنقيط؛',
  'Wicking': 'فتل؛',
  'Irrigation': 'الري؛',
  'm2': 'm2؛',
  'Query data': 'حفظ محصول',
  'Roof Cover': '',
  'SecondSky': '',
  'Standard Plastic': '',
  'Open Field': '',
  'SecondSky Variant': '',
  'SecondSky PolyFilm': '',
  'SecondSky PolyCarbonate': '',
  'SecondSky Net': '',
  'Blocking Ratio': '',
  'Net Type': '',
  '10/20': '',
  '10/16': '',
  'Tricot': '',
  'Rashel': '',
  'SecondSky Additional Information': '',
  'Not Applicable': '',
  'An organization must be selected.': 'يجب تحديد مؤسسة.؛',
  'Contact Name is required.': 'اسم جهة الاتصال مطلوب.؛',
  'Contact Number is required.': 'رقم جهة الاتصال مطلوب.؛',
  'Contact Email is required.': 'البريد الإلكتروني لجهة الاتصال مطلوب.؛',
  'Manage Customers': 'إدارة العملاء؛',
  'Create Customer': 'إنشاء عميل؛',
  'Create New Customer': 'إنشاء عميل جديد؛',
  'Update Existing Customer': 'تحديث عميل موجود؛',
  'Save Customer': 'حفظ العميل؛',
  'No customers': 'لا يوجد عملاء؛',
  'Contract': 'العقد؛',
  'Contract Document': 'وثيقة العقد؛',
  'Registration': 'التسجيل؛',
  'Registration Number': 'رقم التسجيل؛',
  'Registration Document': 'وثيقة التسجيل؛',
  'Add Registration': 'إضافة التسجيل؛',
  'Delete Registration': 'حذف التسجيل؛',
  'Tax Information': 'معلومات الضريبة؛',
  'Tax Number': 'الرقم الضريبي؛',
  'Tax Document': 'وثيقة الضريبة؛',
  'Add Tax Information': 'إضافة المعلومات الضريبية؛',
  'Delete Tax Information': 'حذف المعلومات الضريبية؛',
  'National Address': 'العنوان الوطني؛',
  'National Address Document': 'وثيقة العنوان الوطني؛',
  'Street Address': 'عنوان الشارع؛',
  'Street Address 2': 'عنوان الشارع 2؛',
  'Zip Code': 'الرمز البريدي؛',
  'Add National Address': 'إضافة عنوان وطني؛',
  'Delete National Address': 'حذف العنوان الوطني؛',
  'Bank Details': 'تفاصيل البنك؛',
  'Bank Details Document': 'وثيقة تفاصيل البنك؛',
  'Bank Name': 'اسم البنك؛',
  'Branch Name': 'اسم الفرع؛',
  'Branch Code': 'رمز الفرع؛',
  'Branch Address': 'عنوان الفرع؛',
  'Branch Address 2': 'عنوان الفرع 2؛',
  'Swift/Bic': 'رقم السويفت/رمز تعريف البنك؛',
  'IBAN': 'رقم الحساب المصرفي الدولي؛',
  'Add Bank Details': 'إضافة تفاصيل البنك؛',
  'Delete Bank Details': 'حذف تفاصيل البنك؛',
  'Retail': 'البيع بالتجزئة؛',
  'Wholesale': 'البيع بالجملة؛',
  'Charity': 'العمل الخيري؛',
  'Hospitality': 'الضيافة؛',
  'Open Market': 'السوق المفتوحة؛',
  'Manage Fertilizer Inventory': 'إدارة مخزون الأسمدة؛',
  'No fertilizer inventory': 'لا يوجد مخزون من الأسمدة؛',
  'Transfers': 'عمليات النقل؛',
  'Purchases': 'المشتريات؛',
  'Fertilizer': 'السماد؛',
  'Total Weight': 'الوزن الإجمالي؛',
  'kg': 'كجم؛',
  'Fertilizer inventory is required.': 'مطلوب تحديد مخزون الأسمدة.؛',
  'New total weight is required.': 'مطلوب تحديد الوزن الإجمالي الجديد.؛',
  'New total weight cannot be less than zero.': 'لا يُمكن أن يكون الوزن الإجمالي الجديد أقل من الصفر.؛',
  'A reason is required.': 'مطلوب تحديد سبب.؛',
  'Manage Fertilizer Inventory Calibrations': 'إدارة عمليات معايرة مخزون الأسمدة؛',
  'Create New Calibration': 'إنشاء معايرة جديدة؛',
  'No fertilizer inventory calibrations': 'لا توجد عمليات معايرة لمخزون الأسمدة؛',
  'Save Calibration': 'حفظ المعايرة؛',
  'Inventory Calibration': 'معايرة المخزون؛',
  'Inventory': 'المخزون؛',
  '': 'بيانات الاستعلام',
  'New Total Weight': 'الوزن الإجمالي الجديد؛',
  'Old Total Weight': 'الوزن الإجمالي القديم؛',
  'Current Total Weight': 'الوزن الإجمالي الحالي؛',
  'Change': 'تغيير؛',
  'Please select the origin inventory.': 'يُرجى تحديد المخزون في نقطة المنشأ.؛',
  'Please select a fertilizer.': 'يُرجى تحديد سماد.؛',
  'Please select an origin shipping method.': 'يُرجى تحديد طريقة شحن من نقطة المنشأ.؛',
  'Please select a destination storage facility.': 'يُرجى تحديد منشأة تخزين في نقطة الوجهة.؛',
  'Please select a status.': 'يُرجى تحديد حالة.؛',
  'Total Weight is required.': 'مطلوب تحديد الوزن الإجمالي.؛',
  'Total Weight cannot be less than one.': 'لا يُمكن أن يكون الوزن الإجمالي أقل من 1.؛',
  'At least one weight is required.': 'مطلوب تحديد وزن واحد على الأقل.؛',
  'Shipped date is invalid.': 'تاريخ الشحن غير صالح.؛',
  'The shipped date cannot be set in the future.': 'لا يمكن تحديد تاريخ الشحن في المستقبل.؛',
  'Delivered date is invalid.': 'تاريخ التسليم غير صالح.؛',
  'The delivered date cannot be set in the future.': 'لا يمكن تحديد تاريخ التسليم في المستقبل.؛',
  'Manage Fertilizer Inventory Transfers': 'إدارة عمليات نقل مخزون الأسمدة؛',
  'Transfer': 'النقل؛',
  'Create New Transfer': 'إنشاء عملية نقل جديدة؛',
  'Update Existing Transfer': 'تحديث عملية نقل حالية؛',
  'No fertilizer inventory transfers': 'لا توجد عمليات نقل لمخزون الأسمدة؛',
  'Save Transfer': 'حفظ عملية النقل؛',
  'Inventory Transfer': 'نقل المخزون؛',
  'Origin': 'المنشأ؛',
  'Shipping Method': 'طريقة الشحن؛',
  'Date Shipped': 'تاريخ الشحن؛',
  'Date Delivered': 'تاريخ التسليم؛',
  'Shipped': 'تم الشحن؛',
  'Delivered': 'تم التسليم؛',
  'Add Weight': 'إضافة وزن؛',
  'Units': 'الوحدات؛',
  'A storage facility is required.': 'مطلوب تحديد منشأة تخزين.؛',
  'A fertilizer unit is required.': 'مطلوب تحديد وحدة أسمدة.؛',
  'A supplier is required.': 'مطلوب تحديد مورّد.؛',
  'A currency is required.': 'مطلوب تحديد عملة.؛',
  'A unit price is required.': 'مطلوب تحديد سعر الوحدة.؛',
  'The unit price cannot be less than one.': 'لا يمكن أن يقل سعر الوحدة عن واحد.؛',
  'A unit weight is required.': 'مطلوب تحديد وزن الوحدة.؛',
  'The unit weight cannot be less than 0.1.': 'لا يمكن أن يقل وزن الوحدة عن 0.1.؛',
  'The tax cannot be less than zero.': 'لا يُمكن أن تقل الضريبة عن الصفر.؛',
  'A partial quantity is not allowed.': 'غير مسموح بوجود كمية جزئية.؛',
  'A purchase quantity is required.': 'مطلوب تحديد كمية الشراء.؛',
  'The quantity purchased cannot be less than one.': 'لا يمكن أن تقل كمية الشراء عن واحد.؛',
  'An purchase quantity is required.': 'مطلوب تحديد كمية شراء.؛',
  'Order date is required.': 'مطلوب تحديد تاريخ الطلب.؛',
  'Order date cannot be set in the future.': 'لا يمكن تحديد تاريخ الطلب في المستقبل.؛',
  'Date received is invalid.': 'تاريخ الاستلام غير صالح.؛',
  'Date received cannot be set in the future.': 'لا يمكن تحديد تاريخ الاستلام في المستقبل.؛',
  'Status is required': 'مطلوب تحديد الحالة؛',
  'Manage Fertilizer Purchases': 'إدارة مشتريات الأسمدة؛',
  'Add Purchase': 'إضافة عملية شراء؛',
  'Create Purchase': 'إنشاء عملية شراء؛',
  'Update Existing Purchase': 'تحديث عملية شراء حالية؛',
  'Save Purchase': 'حفظ عملية الشراء؛',
  'No purchases': 'لا توجد عمليات شراء؛',
  'Purchase': 'عملية الشراء؛',
  'Supplier': 'المورّد؛',
  'Quantity': 'الكمية؛',
  'Unit Price': 'سعر الوحدة؛',
  'Unit Weight': 'وحدة الوزن؛',
  'Price': 'السعر؛',
  'Order': 'الطلب؛',
  'Delivery': 'التوصيل؛',
  'Tax': 'الضريبة؛',
  'tax': 'الضريبة؛',
  'Date Ordered': 'تاريخ الطلب؛',
  'Date Received': 'تاريخ الاستلام؛',
  'Ordered': 'الطلبات المُقدَّمة؛',
  'Received': 'المُستلَم؛',
  'Results for date range': 'نتائج نطاق التاريخ',
  'A location must be selected.': 'يجب تحديد موقع.',
  'Variety is required.': 'المجموعة المتنوعة مطلوبة.؛',
  'The harvest date cannot be set in the future.': 'لا يمكن تحديد تاريخ الحصاد في المستقبل.؛',
  'A harvest date is required.': 'مطلوب تحديد تاريخ حصاد.؛',
  'Manage harvests': 'إدارة المحاصيل',
  'Create harvest': 'إنشاء محصول',
  'Plans': 'خطط؛',
  'Create New Harvest': 'إنشاء عملية حصاد جديدة؛',
  'Update Existing Harvest': 'تحديث عملية حصاد موجودة؛',
  'Save Harvest': 'حفظ عملية الحصاد؛',
  'Harvest Date': 'تاريخ الحصاد؛',
  'Yield': 'الناتج؛',
  'Weights': 'الأوزان؛',
  'Weight': 'الوزن؛',
  'Total Weight (kg': 'الوزن الإجمالي (كجم؛',
  'Photos': 'الصور:',
  'No harvests': 'لا توجد عمليات حصاد؛',
  'Are you sure?': 'هل أنت متأكد؟؛',
  'Click continue to confirm.': 'انقر فوق \'متابعة\' للتأكيد.؛',
  'Deleting a harvest cannot be undone.': 'لا يمكن التراجع عن حذف عملية الحصاد.؛',
  'Manage Inventory': 'إدارة المخزون؛',
  'No inventory': 'لا يوجد مخزون؛',
  'Please select the inventory.': 'يُرجى تحديد المخزون.؛',
  'New weight is required.': 'مطلوب تحديد وزن جديد.؛',
  'New weight cannot be less than zero.': 'لا يُمكن أن يكون الوزن الجديد أقل من الصفر.؛',
  'Manage Inventory Calibrations': 'إدارة عمليات معايرة المخزون؛',
  'No inventory calibrations': 'لا توجد عمليات معايرة للمخزون؛',
  'New Weight': 'الوزن الجديد؛',
  'Old Weight': 'الوزن القديم؛',
  'Current Weight': 'الوزن الحالي؛',
  'Total weight cannot be less than 1kg.': 'لا يُمكن أن يكون الوزن الإجمالي أقل من 1 كجم.؛',
  'Total weight is required.': 'مطلوب تحديد الوزن الإجمالي.؛',
  'Manage Inventory Transfers': 'إدارة عمليات نقل المخزون؛',
  'No inventory transfers': 'لا توجد عمليات نقل للمخزون؛',
  'Manage Packaging Inventory': 'إدارة مخزون مواد التعبئة؛',
  'No packaging inventory': 'لا يوجد مخزون من مواد التعبئة؛',
  'Package Unit': 'وحدة التعبئة؛',
  'Package unit inventory is required.': 'مطلوب تحديد مخزون وحدة التعبئة.؛',
  'New quantity is required.': 'مطلوب تحديد الكمية الجديدة.؛',
  'New quantity cannot be less than zero.': 'لا يُمكن أن تكون الكمية الجديدة أقل من الصفر.؛',
  'Manage Packaging Inventory Calibrations': 'إدارة عمليات معايرة مخزون مواد التعبئة؛',
  'No packaging inventory calibrations': 'لا توجد عمليات معايرة لمخزون مواد التعبئة؛',
  'New Quantity': 'الكمية الجديدة؛',
  'Old Quantity': 'الكمية القديمة؛',
  'Current Quantity': 'الكمية الحالية؛',
  'Quantity is required.': 'مطلوب تحديد الكمية.؛',
  'Quantity cannot be less than one.': 'لا يُمكن أن تكون الكمية أقل من واحد.؛',
  'Manage Packaging Inventory Transfers': 'إدارة عمليات نقل مخزون مواد التعبئة؛',
  'No packaging inventory transfers': 'لا توجد عمليات نقل لمخزون مواد التعبئة؛',
  'Packaging': 'التعبئة؛',
  'A package unit is required.': 'مطلوب تحديد وحدة تعبئة.؛',
  'Manage Packaging Purchases': 'إدارة مشتريات مواد التعبئة؛',
  'An estimated harvest start date is required.': 'مطلوب تحديد تاريخ بدء حصاد تقديري.؛',
  'An estimated harvest end date is required.': 'مطلوب تحديد تاريخ نهاية حصاد تقديري.؛',
  'At least one estimated weight is required.': 'مطلوب تحديد وزن تقديري واحد على الأقل.؛',
  'Currency is required.': 'مطلوب تحديد العملة.؛',
  'The target price must be more than zero.': 'يجب أن يزيد السعر المستهدف عن الصفر.؛',
  'A target price is required.': 'مطلوب تحديد سعر مستهدف.؛',
  'Manage Plans': 'إدارة الخطط؛',
  'Create plan': 'وضع خطة',
  'No plans': 'لا توجد خطط؛',
  'Create New Plan': 'إنشاء خطة جديدة؛',
  'Update Existing Plan': 'تحديث خطة موجودة؛',
  'Save Plan': 'حفظ الخطة؛',
  'Target Price': 'السعر المستهدف؛',
  'Estimated Weights': 'الأوزان التقديرية؛',
  'A code is required.': 'مطلوب تحديد رمز.؛',
  'Weight must be at least 1g.': 'يجب ألا يقل الوزن عن 1 جم.؛',
  'Weight is required.': 'مطلوب تحديد الوزن.؛',
  'A grade is required.': 'مطلوب تحديد درجة.؛',
  'At least one package unit is required.': 'مطلوب تحديد وحدة تعبئة واحدة على الأقل.؛',
  'Manage Products': 'إدارة المنتجات؛',
  'Create Product': 'إنشاء منتج؛',
  'No products': 'لا توجد منتجات؛',
  'Create New Product': 'إنشاء منتج جديد؛',
  'Update Existing Product': 'تحديث منتج موجود؛',
  'Save Product': 'حفظ المنتج؛',
  'Product': 'المنتج؛',
  'Discontinued': 'توقف إنتاجه؛',
  'A product must be selected.': 'يجب تحديد منتج.؛',
  'A returned quantity is required.': 'مطلوب تحديد كمية مرتجعة.؛',
  'Returned quantity cannot be less than 1': 'لا يمكن أن تقل الكمية المرتجعة عن 1؛',
  'A quantity returned must be filled': 'يجب ملء حقل الكمية المرتجعة؛',
  'A return date must be selected.': 'يجب تحديد تاريخ الإرجاع.؛',
  'A branch must be selected': 'يجب تحديد فرع؛',
  'A customer must be selected': 'يجب تحديد عميل؛',
  'At least one return item is required': 'مطلوب تحديد مرتجع واحد على الأقل؛',
  'Manage Returns': 'إدارة المرتجعات؛',
  'Create Return': 'إنشاء مرتجع؛',
  'Create New Return': 'إنشاء مرتجع جديد؛',
  'Update Existing Return': 'تحديث مرتجع موجود؛',
  'Return': 'الإرجاع؛',
  'Return Date': 'تاريخ الإرجاع؛',
  'Reference Number': 'الرقم المرجعي؛',
  'No returns': 'لا توجد مرتجعات؛',
  'No items': 'لا توجد عناصر؛',
  'Save Return': 'حفظ المرتجع؛',
  'Add Return Item': 'إضافة مرتجع؛',
  'Items': 'العناصر؛',
  'Return Item': 'المرتجع؛',
  'Amount': 'الكمية؛',
  'Discard': 'تجاهل؛',
  'Quantity Returned': 'الكمية المرتجعة؛',
  'Unordered': 'لا يُطلَب؛',
  'Unsold': 'غير مُباع؛',
  'Damaged': 'تالف؛',
  'Low Quality': 'جودة منخفضة؛',
  'No payments': 'لا توجد مدفوعات؛',
  'The received date cannot be older than the order date.': 'يجب ألا يكون تاريخ الاستلام أقدم من تاريخ الطلب.؛',
  'The received date is required.': 'مطلوب تحديد تاريخ الاستلام.؛',
  'The amount cannot be less than one.': 'لا يمكن أن تقل الكمية عن واحد.؛',
  'An amount is required.': 'مطلوب تحديد كمية.؛',
  'Add Item': 'إضافة عنصر؛',
  'Add New Item': 'إضافة عنصر جديد؛',
  'Update Item': 'تحديث العنصر؛',
  'Recommended': 'موصى به:',
  'Apply': 'تطبيق؛',
  'Discount': 'الخصم؛',
  'Quantity Ordered': 'الكمية المطلوبة؛',
  'Save Item': 'حفظ العنصر؛',
  'Unable to remove item referenced by shipment.': 'تتعذر إزالة عنصر متضمن في شحنة.؛',
  'Please select a product.': 'يُرجى تحديد منتج.؛',
  'An order quantity is required.': 'مطلوب تحديد كمية الطلب.؛',
  'The quantity ordered cannot be less than one.': 'لا يمكن أن تقل الكمية المطلوبة عن واحد.؛',
  'The discount cannot be less than zero.': 'لا يُمكن أن يقل الخصم عن الصفر.؛',
  'No Items': 'لا توجد عناصر؛',
  'Sale Item': 'عنصر البيع؛',
  'Please select a sale item.': 'يُرجى تحديد عنصر للبيع.؛',
  'The quantity cannot be less than one.': 'لا يمكن أن تقل الكمية عن واحد.؛',
  'An quantity is required.': 'مطلوب تحديد كمية.؛',
  'Add Shipment': 'إضافة شحنة؛',
  'Add New Shipment': 'إضافة شحنة جديدة؛',
  'Update Shipment': 'تحديث الشحنة؛',
  'Due Date': 'تاريخ الاستحقاق؛',
  'No shipments': 'لا توجد شحنات؛',
  'Due': 'مُستحَق؛',
  'Shipping From': 'شحن من؛',
  'Save Shipment': 'حفظ الشحنة؛',
  'Delayed': 'متأخر؛',
  'Please select a storage facility.': 'يُرجى تحديد منشأة تخزين.؛',
  'Please select a shipping method.': 'يُرجى تحديد طريقة شحن.؛',
  'The price cannot be less than zero.': 'لا يمكن أن يقل السعر عن الصفر.؛',
  'The due date cannot be older than the order date.': 'يجب ألا يكون تاريخ الاستحقاق أقدم من تاريخ الطلب.؛',
  'A due date must be selected.': 'يجب تحديد تاريخ استحقاق.؛',
  'The delivery date cannot be older than the order date.': 'يجب ألا يكون تاريخ التسليم أقدم من تاريخ الطلب.؛',
  'You must include at least one item.': 'يجب تضمين عنصر واحد على الأقل.؛',
  'An order date must be selected.': 'يجب تحديد تاريخ طلب.؛',
  'A customer PO is required.': 'مطلوب تحديد أمر شراء للعميل.؛',
  'Please select a customer.': 'يُرجى تحديد عميل.؛',
  'Please select a branch.': 'يُرجى تحديد فرع.؛',
  'Please select a currency.': 'يُرجى تحديد عملة.؛',
  'Please select a payment method.': 'يُرجى تحديد طريقة دفع.؛',
  'Please select the payment terms.': 'يُرجى تحديد شروط الدفع.؛',
  'At least one item is required.': 'مطلوب تحديد عنصر واحد على الأقل.؛',
  'Manage Sales': 'إدارة المبيعات؛',
  'Create Sale': 'إنشاء عملية بيع؛',
  'Create New Sale': 'إنشاء عملية بيع جديدة؛',
  'Update Existing Sale': 'تحديث عملية بيع موجودة؛',
  'Save Sale': 'حفظ عملية البيع؛',
  'Purchase Order': 'أمر الشراء؛',
  'Invoice': 'الفاتورة؛',
  'Sale': 'عملية البيع؛',
  'Payments': 'مدفوعات؛',
  'Shipments': 'شحنات؛',
  'Order Date': 'تاريخ الطلب؛',
  'Customer Purchase Order': 'أمر الشراء الخاص بالعميل؛',
  'Invoice Status': 'حالة الفاتورة؛',
  'Invoice Number': 'رقم الفاتورة؛',
  'Add Payment': 'إضافة عملية دفع؛',
  'No sales': 'لا توجد مبيعات؛',
  'In Processing': 'قيد المعالجة؛',
  'In Production': 'قيد الإنتاج؛',
  'In Transit': 'في مرحلة انتقالية؛',
  'Partially Delivered': 'تم التسليم بشكل جزئي؛',
  'Issued': 'تم الإصدار؛',
  'Submitted': 'تم الإرسال؛',
  'Awaiting Approval': 'قيد الموافقة؛',
  'Awaiting Payment': 'قيد الدفع؛',
  'Paid': 'مدفوع؛',
  'inc.': 'يشمل؛',
  'Traceability': 'القدرة على التتبع؛',
  'Changing the organization will delete all items and shipments. This cannot be undone.': 'سوف يؤدي تغيير المؤسسة إلى حذف جميع البنود والشحنات. لا يمكن إلغاء هذا الإجراء.',
  'At least one supply type is required.': 'مطلوب تحديد نوع توريد واحد على الأقل.؛',
  'Website is required.': 'مطلوب تحديد موقع الويب.؛',
  'Manage Suppliers': 'إدارة المورّدين؛',
  'Create Supplier': 'إنشاء مورّد؛',
  'Create New Supplier': 'إنشاء مورّد جديد؛',
  'Update Existing Supplier': 'تحديث مورّد موجود؛',
  'Save Supplier': 'حفظ المورّد؛',
  'No suppliers': 'لا يوجد مورّدون؛',
  'Supply Types': 'أنواع التوريد؛',
  'Contact Person': 'جهة الاتصال؛',
  'Email Address': 'عنوان البريد الإلكتروني؛',
  'Website': 'موقع الويب؛',
  'All Regions': 'كل المناطق؛',
  'Add Address': 'إضافة عنوان؛',
  'Delete Address': 'حذف عنوان؛',
  'Branding': 'العلامة التجارية؛',
  'Seeds': 'البذور؛',
  'Substrate': 'الطبقة التحتية؛',
  'Technology': 'التقنية؛',
  'Construction': 'الإنشاءات؛',
  'Telecommunications': 'الاتصالات؛',
  'Package Date': 'تاريخ التعبئة؛',
  'Generate Code': 'إنشاء رمز؛',
  'Batch#': 'رقم الدفعة؛',
  'Harvest is required.': 'مطلوب تحديد عملية حصاد.؛',
  'The package date cannot be set in the future.': 'لا يمكن تحديد تاريخ التعبئة في المستقبل.؛',
  'A package date is required.': 'مطلوب تحديد تاريخ تعبئة.؛',
  'Customer is required.': 'مطلوب تحديد العميل.؛',
  'Please select customer.': 'يُرجى تحديد العميل.؛',
  'The selling price cannot be less than zero.': 'لا يمكن أن يقل سعر البيع عن الصفر.؛',
  'Manage Unit Prices': 'إدارة أسعار الوحدة؛',
  'Create Unit Price': 'إنشاء سعر وحدة؛',
  'Create New Unit Price': 'إنشاء سعر وحدة جديدة؛',
  'Update Existing Unit Price': 'تحديث سعر الوحدة الموجود؛',
  'No Unit Prices': 'لا توجد أسعار وحدات؛',
  'Save Unit Price': 'حفظ سعر الوحدة؛',
  'Article Code': 'رمز السلعة؛',
  'Alternate Description': 'وصف بديل؛',
  '%': '%؛',
  'discount': 'الخصم؛',
  'Selling Price': 'سعر البيع؛',
  'Article': 'السلعة؛',
  'A produce must be selected.': 'يجب تحديد محصول.؛',
  'A variety must be selected.': 'يجب تحديد مجموعة متنوعة.؛',
  'The waste date cannot be set in the future.': 'لا يمكن تحديد تاريخ عملية الفاقد في المستقبل.؛',
  'A waste date is required.': 'مطلوب تحديد تاريخ لعملية الفاقد.؛',
  'Weights field must have at least 1 item.': 'يجب أن يحتوي حقل الأوزان على عنصر واحد على الأقل.؛',
  'Manage Waste': 'إدارة عمليات الفاقد؛',
  'Create Waste': 'إنشاء عملية فاقد؛',
  'Waste': 'عملية الفاقد؛',
  'Create New Waste': 'إنشاء عملية فاقد جديدة؛',
  'Update Existing Waste': 'تحديث عملية فاقد موجودة؛',
  'Save Waste': 'حفظ عملية الفاقد؛',
  'No waste': 'لا توجد عمليات فاقد؛',
  'Expired': 'منتهي الصلاحية؛',
  'Customize your chart export dimensions': 'تخصيص أبعاد المخطط البياني للتصدير',
  'Width (px': 'العرض (بكسل',
  'Enter a width': 'إدخال العرض',
  'Crop status changes over time by location': 'تغييرات وضع المحاصيل بمرور الوقت بالموقع',
  'Width must be no more than 1920px': 'يجب ألا يزيد العرض عن 1920 بكسل',
  'Not yet planned': 'لم يتم التخطيط له بعد',
  'Harvest started': 'بدأ الحصاد',
  'Harvest ended': 'انتهى الحصاد',
  'Not planned': 'غير مخطط له',
  'Total weight': 'الوزن الكلي',
  'Harvest date': 'تاريخ الحصاد',
  'Harvest data': 'بيانات الحصاد',
  'No data to display': 'لا توجد بيانات لعرضها',
  'Search for harvests': 'البحث عن المحاصيل',
  'View weight by grade': 'الاطلاع على الوزن بالدرجة',
  'No data to show for': 'لا توجد بيانات لإظهارها',
  'Crops data': 'بيانات المحاصيل',
  'Search for crops': 'البحث عن المحاصيل',
  'Planting area m': 'مساحة الزراعة (م)',
  'Seed quantity': 'كمية البذور',
  'Produce weight by grade and location': 'إنتاج الوزن بالدرجة والموقع',
  'Produce weight by location': 'إنتاج الوزن بالموقع',
  'Bar chart of produce by location': 'المخطط الشريطي للإنتاج بالموقع',
  'Table of harvest entries': 'جدول مدخلات الحصاد',
  'Crops status changes over time by location': 'تغييرات وضع المحاصيل بمرور الوقت بالموقع',
  'Crops timelines': 'الجداول الزمنية للمحاصيل',
  'Table of crops entries': 'جدول مدخلات المحاصيل',
  'Unplanned': 'غير مخطط له',
  'All Cities': 'كل المدن؛',
  'All Countries': 'كل البلدان؛',
  'All Customers': 'كل العملاء؛',
  'site': 'الموقع',
  'defaultLabel': 'التسمية الافتراضية؛',
  'All Organizations': 'كل المؤسسات؛',
  'All Package Units': 'كل وحدات التعبئة؛',
  'All Produce': 'كل المحاصيل؛',
  'All Products': 'كل المنتجات؛',
  'All Sites': 'كل المواقع؛',
  'All Varieties': 'كل المجموعات المتنوعة؛',
  'No weights': 'لا توجد أوزان؛',
  'Weight must be at least 0.1kg.': 'يجب ألا يقل الوزن عن 0.1 كجم.؛',
  'Quantity must be at least 1.': 'يجب أن تكون الكمية 1 على الأقل.؛',
  'Add Photo': 'إضافة صورة؛',
  'New Photo': 'صورة جديدة؛',
  'Subject': 'الموضوع؛',
  'Retake': 'صورة ثانية؛',
  'Photo': 'صورة؛',
  'Take Photo': 'التقاط صورة؛',
  'Please allow use of camera': 'يُرجى السماح باستخدام الكاميرا؛',
  'Select Subject': 'تحديد الموضوع؛',
  'None': 'بلا؛',
  'Plant': 'نبات؛',
  'Leaf': 'ورقة؛',
  'Fruit': 'فاكهة؛',
  'Healthy': 'صحي؛',
  'Diseased': 'مصاب بمرض؛',
  'Deformed': 'مشوه؛',
  'Weight (kg': 'الوزن (كجم؛',
  'Select Grade': 'تحديد الدرجة؛',
  'Weight must be at least 1kg.': 'يجب ألا يقل الوزن عن 1 كجم.؛',
  'Package Units': 'وحدات التعبئة؛',
  'Storage Facilities': 'منشآت التخزين؛',
  'Returns': 'المرتجعات؛',
  'Payment Methods': 'طرق الدفع؛',
  'Sales': 'المبيعات؛',
  'Products': 'المنتجات؛',
  'Customers': 'العملاء؛',
  'Admin': 'المسؤول؛',
  'Last Updated:': 'آخر ما تم تحديثه:',
  'Welcome to SecondSky Data, the agricultural data visualization platform. Your privacy is important to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information. This policy is designed to be compliant with the General Data Protection Regulation (GDPR) of the EU, relevant US federal and state privacy laws, and the Personal Data Protection Law of Saudi Arabia.': '',
  '1. Information Collection and Use': '1- جمع المعلومات واستخدامها',
  'Personal Data: We collect personal data such as name, email address, and location when you register on SecondSky Data. This information is used to provide and improve our services.': '',
  'Agricultural Data: We collect agricultural data for visualization and analysis. This data may include geographic, environmental, crop, yield, and other operational information.': 'البيانات الزراعية: نقوم بتجميع البيانات الزراعية من أجل التصور والتحليل. مقد تشتمل هذه البيانات على معلومات جغرافية وبيئية وأخرى تتعلق بالمحاصيل وغيرها من المعلومات التشغيلية.',
  '2. Consent': '2- الموافقة',
  'By using SecondSky Data, you consent to the collection and use of your information as outlined in this policy. For users in the EU, we comply with GDPR requirements for explicit consent.': '',
  '3. Data Sharing and Disclosure': '3- مشاركة البيانات والإفصاح عنها',
  'We do not sell your personal data. We may share your information with third parties for the purposes of improving our services, complying with the law, or in the event of a business transfer.': 'نحن لا نبيع بياناتك الشخصية. إننا قد نشارك معلوماتك مع أطراف أخرى لأغراض تحسين خدماتنا مع الامتثال للقانون أو في حالة نقل الأعمال. ',
  'Cross-Border Data Transfers: Your data may be stored and processed in any country where we have operations or where we engage service providers.': 'عمليات نقل البيانات عبر الحدود: يمكن تخزين بياناتك ومعالجتها في أي بلد لدينا فيه عمليات أو حيث نستعين بمقدمي خدمات.',
  '4. Data Security': '4. أمن البيانات',
  'We implement appropriate technical and organizational measures to protect your data from unauthorized access, disclosure, alteration, and destruction.': 'ونحن نطبق إجراءات فنية وتنظيمية مناسبة لحماية بياناتك من عمليات الوصول والإفصاح والتغيير والإتلاف، غير المصرح بها.',
  '5. Your Rights': '5- حقوقك',
  'EU Users: Under the GDPR, you have the right to access, correct, delete, or restrict the processing of your personal data.': 'المستخدمين في دول الاتحاد الأوروبي: بموجد اللائحة العامة لحماية البيانات \'GDPR\'، يكون من حقك الوصول إلى بياناتك الشخصية أو تصحيحها أو حذفها أو تقييدها أو معالجتها.',
  'US Users: Depending on your state, you may have specific rights regarding access to and control of your personal data.': 'المستخدمين في الولايات المتحدة الأمريكية: بحسب ولايتك، يمكن أن تكون لك حقوق معينة تتعلق بالوصول إلى، والتحكم في بياناتك الشخصية.',
  'Saudi Arabian Users: In accordance with the Personal Data Protection Law, you have rights regarding the processing of your personal data.': 'المستخدمين في المملكة العربية السعودية: طبقا لقانون حماية البيانات الشخصية، لك حقوق تتعلق بمعالجة بياناتك الشخصية.',
  '6. Data Retention': '6. الاحتفاظ بالبيانات',
  'We retain your personal data only as long as necessary to provide you with our services and for legitimate and essential business purposes.': 'نحن نحتفظ ببياناتك الشخصية فقط طالما كانت ضرورية لتزويدك بخدماتنا ولأغراض تجارية مشروعة وأساسية.',
  '7. Changes to This Policy': '7. التغييرات في هذه السياسة',
  'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page.': 'قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. وسوف نبلغك بأية تغييرات من خلال نشر السياسة الجديدة على هذه الصفحة.',
  '8. Contact Us': '8. اتصل بنا',
  'If you have any questions about this Privacy Policy, please contact us at support@secondsky.com': '',
  'WhatsApp number': '',
  'verified': '',
  'Message our chatbot at this number': '',
  'WhatsApp number not': '',
  'yet.': '',
  '\'Verify me\'': '',
  'to our number': '',
  'Profile': 'ملف التعريف؛',
  'Enter phone number': 'أدخل رقم الهاتف؛',
  'Include country code': 'ضمِّن رمز البلد؛',
  'WhatsApp Number': 'رقم الواتساب',
  'Phone number updated successfully.': 'تم تحديث رقم الهاتف بنجاح.؛',
  'An error occurred while updating your phone number. Please try again.': 'حدث خطأ أثناء تحديث رقم هاتفك. يُرجى إعادة المحاولة.',
  'Update phone number': 'تحديث رقم الهاتف؛',
  'Update WhatsApp number': 'تحديث رقم الواتساب',
  'WhatsApp number updated successfully.': 'تم تحديث رقم الواتساب بنجاح.',
  'An error occurred while updating your WhatsApp number. Please try again.': 'حدث خطأ أثناء تحديث رقم الواتساب الخاص بك. يُرجى إعادة المحاولة.',
  'WhatsApp number deleted successfully.': '',
  'An error occurred while deleting your WhatsApp number. Please try again.': '',
  'Preferred Language': 'اللغة المفضلة',
  'Update language': 'لغة التحديث',
  'Preferred language updated successfully.': 'تم تحديث اللغة المفضلة بنجاح.',
  'An error occurred while updating your preferred language. Please try again.': 'حدث خطأ أثناء تحديث لغتك المفضلة. يُرجى إعادة المحاولة.',
  'Multi-factor Authentication': 'مصادقة متعددة العوامل',
  'Setup': 'إعداد',
  'Disable': 'تعطيل',
  'Submit code': 'أرسل الرمز',
  'Open your Authenticator app and scan the QR code above. Enter the code from the app to complete the setup.': 'قم بفتح تطبيق المصادقة الخاص بك ومسح رمز الاستجابة السريعة أعلاه. أدخل الرمز من التطبيق لإكمال الإعداد.',
  'Enter code': 'أدخل الرمز',
  'TOTP': 'كلمة مرور لمرة واحدة مستندة إلى الوقت',
  'Multi-factor authentication enabled successfully.': 'تم تمكين المصادقة متعددة العوامل بنجاح.',
  'Multi-factor authentication disabled successfully.': 'تم تعطيل المصادقة متعددة العوامل بنجاح.',
  'Code mismatch.': 'عدم تطابق الرمز.',
  'An error occurred while enabling multi-factor authentication. Please try again.': 'حدث خطأ أثناء تمكين المصادقة متعددة العوامل. يُرجى إعادة المحاولة.',
  'Reset dashboards': 'إعادة ضبط لوحات المعلومات',
  'Reset your dashboards to defaults': 'أعد ضبط لوحات المعلومات الخاصة بك للإعدادات الافتراضية',
  'Set up MFA': 'إعداد المصادقة متعددة العوامل \'MFA\'',
  'Update your profile settings': 'حدّث إعدادات ملف بياناتك الشخصية',
  'By verifying your WhatsApp number you agree to receive automated notifications.': '',
  'You have agreed to receive automated notifications on this number.': '',
  'Light': 'الضوء؛',
  'Dark': '',
  'Preferred Color Scheme': '',
  'Preferred color scheme updated successfully': '',
  'An error occured while trying to set preferred color scheme. Please try again.': '',
  'Reports': 'التقارير',
  'Generate new report': 'إعداد تقرير جديد',
  'Download report': 'تنزيل التقرير',
  '30 days (daily': '30 يومًا (يوميًا',
  '7 days (daily': '7 أيام (يوميًا)',
  'Report ID': 'الرمز التعريفي للتقرير',
  'Report date': 'تاريخ التقرير',
  'From date': 'من تاريخ',
  'To date': 'حتى الآن',
  'No reports exist for the selected filters': 'لا توجد تقارير للمرشحات المحددة',
  'Please select a report start date': 'يُرجى تحديد تاريخ بدء للتقرير',
  'Fetching reports for selected start date...': 'إحضار تقارير لتاريخ بدء محدد...',
  'Error fetching reports': 'خطأ في إحضار تقارير',
  'Report generation in progress': 'جاري إعداد التقارير',
  'The report generation process can take some time. You can now leave this page. An email will be sent to you once your report is available for download.': 'يمكن أن تستغرق عملية إعداد التقارير بعض الوقت. يمكنك الآن ترك هذه الصفحة. سوف يتم إرسال رسالة بريد إلكتروني إليك بمجرد أن يكون تقريرك متاح للتنزيل.',
  'Reset zoom': '',
  'Select a': 'حدد',
  'OK': 'حسنًا',
  'Founding Partners': 'الشركاء المؤسسون؛',
  'Retrieving data failed with error:': 'فشل استرداد البيانات بسبب خطأ.؛',
  'has': 'يوجد؛',
  'alert': 'تنبيه؛',
  'alerts': 'تنبيهات؛',
  'above': 'أكبر من؛',
  'below': 'أقل من؛',
  'threshold': 'العتبة؛',
  'No Thresholds set': 'لم يتم تعيين عتبات؛',
  'PH': 'درجة الحموضة؛',
  'UV A': 'أشعة فوق بنفسجية أ؛',
  'Envirosense PV Power': 'الطاقة الكهروضوئية لـ Envirosense؛',
  'PV Current': 'التيار الكهربائي الكهروضوئي؛',
  'PV Voltage': 'الجهد الكهروضوئي؛',
  'PV Load Voltage': 'جهد التحميل الكهروضوئي؛',
  'PV Load Current': 'تيار التحميل الكهروضوئي؛',
  'PV VMP': 'الجهد الكهروضوئي عند الحد الأقصى للطاقة؛',
  'PV Max Output Power': 'طاقة الإخراج القصوى الكهروضوئية؛',
  'Photo Fan Output Power': 'طاقة إخراج المروحة الكهروضوئية؛',
  'Photo Fan Motor Speed': 'سرعة محرك المروحة الكهروضوئية؛',
  'Photo Fan VFD Output Freq': 'تردد إخراج المحرك متغير التردد للمروحة الكهروضوئية؛',
  'A new version is available.': 'يتوفر إصدار جديد.؛',
  'Click here to update.': 'انقر هنا للتحديث.؛',
  'See the latest updates on SecondSky Data': '',
  'No results': '',
  'View alerts': '',
  'View overview': '',
  'Alerts': '',
  'Search by sensor name': '',
  'Cellular Envirosense': '',
  'Cellular Watersense': '',
  'Cellular Soilsense': '',
  'Notification': '',
  'Caution': '',
  'Offline': '',
  'Is in use': '',
  'Threshold': '',
  'Expand all': '',
  'Collapse all': '',
  'Expand': '',
  'Collapse': '',
  'Export CSV': '',
  'Reset all': '',
  'Zone ID': 'معرِّف المنطقة؛',
  'Sensor ID': '',
  'Sensor type': '',
  'Hardware version': '',
  'Threshold value': '',
  'Last value': '',
  'Sending data': '',
  'Threshold trigger': '',
  'Last sending': '',
  'Last checked': '',
  'Alert': '',
  'Go to sensor dashboard': '',
  'Select a device type': '',
  'Please enter an email': 'يُرجى إدخال بريد إلكتروني',
  'View factory suppliers': 'الاطلاع على موردي المصنع',
  'No suppliers found': 'لم يتم العثور على موردين',
  'Add Supplier': 'إضافة مورد',
  'Admin Users': 'المستخدمون المدراء',
  'See Users': 'معرفة المستخدمين',
  'Supplier created': 'تم إنشاء مورد',
  'User created': 'تم إنشاء مستخدم',
  'User deleted': 'حذف مستخدم',
  'Trial Notebook': '',
  'notes': '',
  'Add Note': '',
  'Adding...': '',
  'Add a new observation or note...': '',
  'No notes have been added yet. Add your first observation above.': '',
  'Delete note': '',
  'Error loading notes': '',
  'Error adding note': '',
  'Error deleting note': '',
  'Advanced View': '',
  'Basic View': '',
  'Edit Trial Info': '',
  'Generate PDF Report': '',
  'days': '',
  'No crops data available': '',
  'Crop details': '',
  'In Progress': '',
  'Completed': '',
  'Cancelled': '',
  'Started': '',
  'Duration': '',
  'Seed Quantity': '',
  'Sow Date': '',
  'Transplant Date': '',
  'Date & Time': '',
  'Additional Information': '',
  'Environmental': '',
  'Metrics': '',
  'Live Data': 'بيانات مباشرة؛',
  'Historical': '',
  'Show Sections': '',
  'Show Delta': '',
  'Section A': '',
  'Section B': '',
  'Delta': '',
  'Clear Filter': '',
  'Sensor Filter': '',
  'Select sensors...': '',
  'Business Insights': '',
  'Experimental Feature': '',
  'Water': '',
  'Cumulative values since the start of the trial for the entire site ': '',
  'Total Cost Savings': '',
  'Iyris': '',
  'Control': '',
  'Savings (B-A': '',
  'No trial data available': '',
  'Error loading trial': '',
  'error?.message || Unknown error': '',
  'crop.status': '',
  'crop.hydrationMethod': '',
  'User profile': '',
  'Support request successfully sent.': 'تم إرسال طلب الدعم بنجاح.',
  'Email could not be sent.': 'لم يكن في الإمكان إرسال بريد إلكتروني.',
  '\'Cant find what youre looking for? Visit the \'': '\'ألا يمكنك إيجاد ما تبحث عنه؟  قم بزيارة \'',
  'Help Centre': 'مركز المساعدة',
  'or': 'أو',
  'Send us a Message': 'أرسل لنا رسالة',
  'Recommended Articles': 'مقالات موصى بها',
  'See': 'الاطلاع على',
  'more articles': 'مزيد من المقالات',
  'Running into problems?': 'هل تواجه مشاكل؟',
  'Visit our': 'زُر',
  'helpdesk': 'مكتب المساعدة الخاص بنا',
  'or fill out the form to contact support.': 'أو استوفي النموذج للتواصل مع فريق الدعم.',
  'Enter your full name here': 'أدخل اسمك بالكامل هنا',
  'Enter your email address here': 'أدخل عنوان بريدك الإلكتروني هنا.',
  'Support Details': 'تفاصيل الدعم',
  'Support Type': 'نوع الدعم',
  'Select support type': 'حدد نوع الدعم',
  'Title/Subject': 'العنوان/الموضوع',
  'Provide a title or subject': 'تقديم عنوان أو موضوع',
  'Describe your problem': 'صف مشكلتك',
  'Please describe your problem or difficulty here': 'يرجى وصف مشكلتك أو الصعوبة التي تواجهها هنا',
  'Submit Request': 'تقديم الطلب',
  'Please provide a name': 'يرجى كتابة اسم',
  'Name cannot be empty': 'لا يمكن أن تكون خانة الاسم فارغًة',
  'Name cannot consist of only spaces': 'لا يمكن أن يتكون الاسم من مسافات فقط',
  'Please provide a valid email address': 'يرجى تقديم عنوان بريد إلكتروني صالح',
  'Please provide a subject': 'يرجى تقديم موضوع',
  'Description must be no more than 255 characters': 'يجب ألا يزيد الوصف عن 255 حرفًا',
  'Please provide a description': 'يرجى تقديم وصف',
  'Select location': 'حدد موقعًا',
  'Select option': 'حدد خيارًا',
  'Models': 'النماذج',
  'Recommendations': '',
  'Threshold Messages': '',
  'Back to menu': 'العودة إلى القائمة',
  'Update Location': 'حدث الموقع',
  'About': 'نبذة عن؛',
  'Trial Mode': '',
  'Dashboard': 'لوحة المعلومات.؛',
  'Map': '',
  'Video Feeds': 'موجزات الفيديو؛',
  'Blueprint': 'مخطط',
  'Packaging Units': 'وحدات التعبئة والتغليف',
  'Branches': 'الفروع',
  'Unit Prices': 'أسعار الوحدات',
  'Enroll a device': 'تسجيل جهاز؛',
  'User management': 'إدارة المستخدمين؛',
  'User roles': 'أدوار المستخدمين؛',
  'User options': 'خيارات المستخدم؛',
  'System thresholds': 'عتبات النظام؛',
  'Notifications': 'إشعارات؛',
  'Sign in': 'تسجيل الدخول؛',
  'Sign out': 'تسجيل الخروج؛',
  'is': 'يكون؛',
  'status': 'الحالة؛',
  'Set Target Temperature': 'تعيين درجات الحرارة المستهدفة؛',
  'Calibrate dissolved oxygen': 'معايرة الأكسجين المذاب؛',
  'Calibrate electrical conductivity': 'معايرة إمكانية التوصيل الكهربي؛',
  'Calibrate Ph': 'معايرة درجة الحموضة؛',
  'Calibrate PAR': 'معايرة الإشعاع النشط ضوئيًا؛',
  'Calibrate Humidity': 'معايرة الرطوبة؛',
  'Calibrate Temperature': 'معايرة درجة الحرارة؛',
  'No Signal': 'لا توجد إشارة؛',
  'Start stream': 'بدء البث؛',
  'Stop stream': 'إيقاف البث؛',
  'Or': 'أو؛',
  'Watch Archive footage': 'مشاهدة لقطات أرشيفية؛',
  'Hide Archive footage': 'إخفاء اللقطات الأرشيفية؛',
  'View Video Feed': 'عرض موجز الفيديو؛',
  'Available Video Feeds': 'موجزات الفيديو المتاحة؛',
  'No available feeds': 'لا توجد موجزات متاحة؛',
  'Search footage': 'البحث في لقطات الفيديو؛',
  'Heatmap': 'خريطة الحرارة؛',
  'Filter': 'مرشح الفرز',
  'Could not create user. Please try again.': 'لم يكن في الإمكان إنشاء مستخدم. يُرجى إعادة المحاولة.',
  'Are you sure you want to delete this item? This action cannot be undone.': '',
  'Toggle': '',
  'dark mode': '',
  'light mode': '',
  'system mode': '',
  'The document must be a pdf file.': 'يجب أن تكون الوثيقة بتنسيق pdf.؛',
  'Drop the file here': 'أفلت الملف هنا؛',
  '\'Click to chose a file or dragndrop it here\'': '\'انقر لاختيار ملف أو اسحبه وأفلته هنا\'؛',
  'go to': 'انتقال إلى؛',
  'next': 'التالي؛',
  'last': 'الأخير؛',
  'previous': 'السابق؛',
  'first': 'الأول؛',
  'Pagination Navigation': 'التنقل بين الصفحات؛',
  'of': 'من؛',
  'items': 'العناصر؛',
  'pages': 'صفحة/صفحات؛',
  'page': 'صفحة؛',
  'You do not have permission to view this page.': '',
  'Start creation of default deployment successfully.': 'تم البدء في إنشاء نشر افتراضي بنجاح.',
  'Started core device updates successfully': 'تم البدء في تحديثات جهاز أساسي بنجاح',
  'Error fetching core device provision installer': 'خطأ في إحضار مركب برنامج تجهيز لجهاز أساسي',
  'Area created successfully.': 'تم إنشاء المنطقة بنجاح.؛',
  'Area updated successfully.': 'تم تحديث المنطقة بنجاح.؛',
  'Plan created successfully.': 'تم إنشاء الخطة بنجاح.؛',
  'Plan updated successfully.': 'تم تحديث الخطة بنجاح.؛',
  'Harvest created successfully.': 'تم إنشاء عملية الحصاد بنجاح.؛',
  'Harvest updated successfully.': 'تم تحديث عملية الحصاد بنجاح.؛',
  'Harvest deleted successfully.': 'تم حذف عملية الحصاد بنجاح.؛',
  'Sale created successfully.': 'تم إنشاء عملية البيع بنجاح.؛',
  'Sale updated successfully.': 'تم تحديث عملية البيع بنجاح.؛',
  'Sale item created successfully.': 'تم إنشاء عنصر البيع بنجاح.؛',
  'Sale item updated successfully.': 'تم تحديث عنصر البيع بنجاح.؛',
  'Unable to remove item referenced by existing return.': 'تتعذر إزالة عنصر متضمن في مرتجع موجود.؛',
  'Sale Shipment created successfully.': 'تم إنشاء شحنة البيع بنجاح.؛',
  'Sale Shipment successfully.': 'تم شحن عملية البيع بنجاح.؛',
  'Unable to remove shipment referenced by existing return.': 'تتعذر إزالة شحنة متضمنة في مرتجع موجود.؛',
  'Waste created successfully.': 'تم إنشاء عملية فاقد بنجاح.؛',
  'Waste updated successfully.': 'تم تحديث عملية الفاقد بنجاح.؛',
  'Return created successfully.': 'تم إنشاء المرتجع بنجاح.؛',
  'Return updated successfully.': 'تم تحديث المرتجع بنجاح.؛',
  'Customer created successfully.': 'تم إنشاء العميل بنجاح.؛',
  'Customer updated successfully.': 'تم تحديث العميل بنجاح.؛',
  'Branch created successfully.': 'تم إنشاء الفرع بنجاح.؛',
  'Branch updated successfully.': 'تم تحديث الفرع بنجاح.؛',
  'Unit price created successfully.': 'تم إنشاء سعر الوحدة بنجاح.؛',
  'Unit price updated successfully.': 'تم تحديث سعر الوحدة بنجاح.؛',
  'Unit price deleted successfully.': 'تم حذف سعر الوحدة بنجاح.؛',
  'Unable to create duplicate unit price.': 'يتعذر إنشاء سعر وحدة مكرر.؛',
  'Unable to save duplicate unit price.': 'يتعذر حفظ سعر الوحدة المكرر.؛',
  'City created successfully.': 'تم إنشاء المدينة بنجاح.؛',
  'City updated successfully.': 'تم تحديث المدينة بنجاح.؛',
  'Country created successfully.': 'تم إنشاء البلد بنجاح.؛',
  'Country updated successfully.': 'تم تحديث البلد بنجاح.؛',
  'Currency created successfully.': 'تم إنشاء العملة بنجاح.؛',
  'Currency updated successfully.': 'تم تحديث العملة بنجاح.؛',
  'Grade created successfully.': 'تم إنشاء الدرجة بنجاح.؛',
  'Grade updated successfully.': 'تم تحديث الدرجة بنجاح.؛',
  'Package unit created successfully.': 'تم إنشاء وحدة التعبئة بنجاح.؛',
  'Package unit updated successfully.': 'تم تحديث وحدة التعبئة بنجاح.؛',
  'Payment method created successfully.': 'تم إنشاء طريقة الدفع بنجاح.؛',
  'Payment method updated successfully.': 'تم تحديث طريقة الدفع بنجاح.؛',
  'Payment terms created successfully.': 'تم إنشاء شروط الدفع بنجاح.؛',
  'Payment terms updated successfully.': 'تم تحديث شروط الدفع بنجاح.؛',
  'Produce created successfully.': 'تم إنشاء المحصول بنجاح.؛',
  'Produce updated successfully.': 'تم تحديث المحصول بنجاح.؛',
  'Region created successfully.': 'تم إنشاء المنطقة بنجاح.؛',
  'Region updated successfully.': 'تم تحديث المنطقة بنجاح.؛',
  'Shipping method created successfully.': 'تم إنشاء طريقة الشحن بنجاح.؛',
  'Shipping method updated successfully.': 'تم تحديث طريقة الشحن بنجاح.؛',
  'Storage facility created successfully.': 'تم إنشاء منشأة التخزين بنجاح.؛',
  'Storage facility updated successfully.': 'تم تحديث منشأة التخزين بنجاح.؛',
  'Variety created successfully.': 'تم إنشاء المجموعة المتنوعة بنجاح.؛',
  'Variety updated successfully.': 'تم تحديث المجموعة المتنوعة بنجاح.؛',
  'Inventory calibration created successfully.': 'تم إنشاء معايرة المخزون بنجاح.؛',
  'Inventory transfer created successfully.': 'تم إنشاء عملية نقل المخزون بنجاح.؛',
  'Inventory transfer updated successfully.': 'تم تحديث عملية نقل المخزون بنجاح.؛',
  'Package unit purchase created successfully.': 'تم إنشاء عملية شراء وحدة التعبئة بنجاح.؛',
  'Package unit purchase updated successfully.': 'تم تحديث عملية شراء وحدة التعبئة بنجاح.؛',
  'Packaging inventory calibration created successfully.': 'تم إنشاء عملية معايرة لمخزون مواد التعبئة بنجاح.؛',
  'Packaging inventory transfer created successfully.': 'تم إنشاء عملية نقل لمخزون مواد التعبئة بنجاح.؛',
  'Packaging inventory transfer updated successfully.': 'تم تحديث عملية نقل لمخزون مواد التعبئة بنجاح.؛',
  'Product created successfully.': 'تم إنشاء المنتج بنجاح.؛',
  'Product updated successfully.': 'تم تحديث المنتج بنجاح.؛',
  'Crop created successfully.': 'تم إنشاء المحصول بنجاح.؛',
  'Crop updated successfully.': 'تم تحديث المحصول بنجاح.؛',
  'Supplier created successfully.': 'تم إنشاء المورّد بنجاح.؛',
  'Supplier updated successfully.': 'تم تحديث المورّد بنجاح.؛',
  'Fertilizer purchase created successfully.': 'تم إنشاء عملية شراء السماد بنجاح.؛',
  'Fertilizer purchase updated successfully.': 'تم تحديث عملية شراء السماد بنجاح.؛',
  'Fertilizer inventory calibration created successfully.': 'تم إنشاء عملية معايرة لمخزون الأسمدة بنجاح.؛',
  'Fertilizer inventory transfer created successfully.': 'تم إنشاء عملية نقل لمخزون الأسمدة بنجاح.؛',
  'Fertilizer inventory transfer updated successfully.': 'تم تحديث عملية نقل لمخزون الأسمدة بنجاح.؛',
  'Harvest data not fetched': 'لم تُقدم بيانات حصاد',
  'Crops data not fetched': 'لم تُقدم بيانات محاصيل',
  'Error fetching control device config': 'حدث خطأ في إيجاد تهيئة لجهاز التحكم',
  'Control device config saved successfully': 'تم حفظ تهيئة جهاز التحكم بنجاح',
  'Control device config updated successfully': 'تم تحديث تهيئة جهاز التحكم بنجاح',
  'Control device config deleted successfully': 'تم حذف تهيئة جهاز التحكم بنجاح',
  'Control device spec saved successfully': 'تم حفظ مواصفات جهاز التحكم بنجاح',
  'Control device spec updated successfully': 'تم تحديث مواصفات جهاز التحكم بنجاح',
  'Diagnostics request sent succesfully. You will receive an email with the results.': '',
  'Sensor restart has been scheduled for the next wake up cycle.': '',
  'SIM status updated successfully.': '',
  'Could not update the SIM status. Our team has been notified and will investigate.': '',
  'Some of the SIM pings failed. Our team has been notified and will investigate.': '',
  'Could not fetch help text entries': 'تعذر إحضار مدخلات لنص المساعدة',
  'Translations requested': 'ترجمات تم طلبها',
  'Could not generate translations': 'تعزر إنشاء ترجمات',
  'Help text entry created': 'تم إنشاء مدخل لنص المساعدة',
  'Could not create help text entry': 'تعذر إنشاء مدخل لنص المساعدة',
  'Help text updated': 'تم تحديث نص المساعدة',
  'Could not update help text': 'تعذر تحديث نص المساعدة',
  'Text could not be generated from prompt': 'تعذر إنشاء النص من الموجه',
  'Release note created': 'تم إنشاء مذكرة الإصدار',
  'Could not create release note': 'تعذر إنشاء مذكرة إصدار',
  'Release note updated': 'تم تحديث مذكرة الإصدار',
  'Could not update release note': 'تعذر تحديث مذكرة الإصدار',
  'Release note section created': 'تم إنشاء قسم مذكرات الإصدار',
  'Could not create release note section': 'تعذر إنشاء قسم مذكرات الإصدار',
  'Release note section updated': 'تم تحديث قسم مذكرات الإصدار',
  'Could not update release note section': 'تعذر تحديث قسم مذكرات الإصدار',
  'Could not fetch all release notes data': 'تعذر إحضار جميع بيانات مذكرات الإصدار',
  'Could not fetch release notes': 'تعذر إحضار مذكرات الإصدار',
  'Translations are being generated': 'جاري إعداد الترجمات',
  'Translations could not be generated': 'تعذر إعداد ترجمات',
  'Could not fetch tags': 'تعذّر تقديم علامات',
  'Tag created': 'تم إنشاء العلامات',
  'Could not create tag': 'تعذر إنشاء العلامات',
  'Tag updated': 'تم تحديث العلامات',
  'Could not update tag': 'تعذر تحديث العلامات',
  'Regeneration of device firmware started successfully.': 'بدأ تجديد البرامج الثابتة للجهاز بنجاح.',
  'Organization label created successfully.': 'تم إنشاء تصنيف المؤسسة بنجاح.',
  'Error creating the label. Please try again later.': 'خطأ في إنشاء العلامة الميزة. يُرجى إعادة المحاولة لاحقًا.',
  'Organization label updated successfully.': 'تم تحديث العلامة المميزة للمؤسسة بنجاح.',
  'Error updating the label. Please try again later.': 'حدث خطأ في تحديث العلامة المميزة. يُرجى إعادة المحاولة لاحقًا.',
  'Organization label translations updated successfully.': 'تم تحديث ترجمات العلامة المميزة للمؤسسة بنجاح.',
  'Error updating the label translations. Please try again later.': 'حدث خطأ في تحديث ترجمات العلامة المميزة. يُرجى إعادة المحاولة لاحقًا.',
  'Error fetching trials': '',
  'Error fetching trial': '',
  'created successfully': 'تم الإنشاء بنجاح؛',
  'Error creating': 'خطأ أثناء الإنشاء؛',
  'sucessfully updated!': 'تم التحديث بنجاح!',
  'Error updating': 'خطأ في التحديث؛',
  'Error updating zone maintenance mode': 'حدث خطأ في تحديث وضع صيانة النطاق',
  'Error updating zone trial mode view': '',
  'Device statuses fetched': 'تم جلب أوضاع الجهاز',
  'Snooze not fetched': 'لم يتم جلب حالة التأجيل',
  'Snoozes not fetched': 'لم يتم جلب حالات التأجيل',
  'Snooze not created': 'لم يتم إنشاء حالة التأجيل',
  'Snooze not deleted': 'لم يتم حذف حالة التأجيل',
  'Username/email is required': 'اسم المستخدم/البريد الإلكتروني مطلوب',
  'Password is required': 'مطلوب إدخال كلمة المرور؛',
  'Code is required': 'مطلوب تحديد الرمز؛',
  'Confirm password is required': 'مطلوب تأكيد كلمة المرور؛',
  'Organization name is required': 'اسم المؤسسة مطلوب',
  'Email format is incorrect': 'صيغة البريد الإلكتروني غير صحيحة',
  'Email is required': 'البريد الإلكتروني مطلوب',
  'First name is required': 'الاسم الأول مطلوب',
  'Last name is required': 'إسم العائلة مطلوب',
  'Username is required': 'مطلوب تحديد اسم المستخدم؛'
}}

export default words