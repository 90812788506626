import { useMemo } from 'react'

export const useBusinessInsights = () => {
  const calculateData = (
    sectionAValue,
    sectionBValue,
    price,
    unit,
    isResourceSaving = true
  ) => {
    const variation = sectionAValue - sectionBValue
    const variationPercentage = parseFloat(
      ((variation / sectionAValue) * 100).toFixed(1)
    )
    const sectionAPrice = `$${(sectionAValue * price).toFixed(2)}`
    const sectionBPrice = `$${(sectionBValue * price).toFixed(2)}`
    const priceDifference = `$${(isResourceSaving
      ? -1 * variation * price
      : variation * price
    ).toFixed(2)}`

    return {
      sectionA: {
        value: sectionAValue,
        price: sectionAPrice
      },
      sectionB: {
        value: sectionBValue,
        price: sectionBPrice
      },
      variation: isResourceSaving ? variation * -1 : variation,
      variationPercentage,
      priceDifference,
      price: `$${price}`,
      unit,
      isResourceSaving
    }
  }

  const energyData = useMemo(() => calculateData(51110, 56851, 0.08, 'KW'), [])

  const waterData = useMemo(() => calculateData(407, 679, 0.46, 'm³'), [])

  const fertigationData = useMemo(() => calculateData(706, 838, 0.68, 'm³'), [])

  const yieldData = useMemo(
    () => calculateData(952, 1167, 1.08, 'Kg', false),
    []
  )

  const totalSavings = useMemo(() => {
    const getValueFromPriceDiff = priceDiff => {
      if (!priceDiff || typeof priceDiff !== 'string') return 0
      return parseFloat(priceDiff.replace('$', ''))
    }

    const energySavings = getValueFromPriceDiff(energyData.priceDifference)
    const waterSavings = getValueFromPriceDiff(waterData.priceDifference)
    const fertigationSavings = getValueFromPriceDiff(
      fertigationData.priceDifference
    )
    const yieldSavings = getValueFromPriceDiff(yieldData.priceDifference)

    const total =
      energySavings + waterSavings + fertigationSavings + yieldSavings

    return {
      value: total,
      formatted: `$${total.toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      })}`,
      breakdown: {
        energy: energySavings,
        water: waterSavings,
        fertigation: fertigationSavings,
        yield: yieldSavings
      }
    }
  }, [energyData, waterData, fertigationData, yieldData])

  return {
    energyData,
    waterData,
    fertigationData,
    yieldData,
    totalSavings
  }
}
