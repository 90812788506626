import { useState } from 'react'
import Card from '@/primitives/Card'
import MetricsHeader from './MetricsHeader'
import HistoricalView from './HistoricalView'
import LiveView from './LiveView'
import { useDashboardMetrics } from './hooks/useDashboardMetrics'
import { useViewMode } from './hooks/useViewMode'
import Slot from '@/primitives/Slot'
import Collapse from '@/primitives/Collapse'

const MetricsCard = ({
  type = 'envirosense',
  dashboardData,
  sectionA,
  sectionB,
  metrics,
  className = '',
  isSmallScreen,
  ...rest
}) => {
  const [selectedMetric, setSelectedMetric] = useState(metrics[0]?.id || '')
  const { viewMode, showDelta, toggleDelta, setViewMode } = useViewMode()

  const transformedData = useDashboardMetrics(
    dashboardData,
    sectionA?.id,
    sectionB?.id,
    metrics
  )

  const selectedMetricObj = metrics.find(m => m.id === selectedMetric)

  if (isSmallScreen)
    return (
      <Card
        className={className}
        {...rest}
        style={{
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          position: 'relative',
          border: 'none',
          width: '100%'
        }}
      >
        <Slot name='body'>
          <Collapse defaultOpen={!isSmallScreen}>
            <Slot name='trigger'>
              <MetricsHeader
                type={type}
                viewMode={viewMode}
                showDelta={showDelta}
                onDeltaToggle={toggleDelta}
                onViewModeChange={setViewMode}
                sectionAId={sectionA?.id}
                sectionBId={sectionB?.id}
                isSmallScreen={isSmallScreen}
              />
            </Slot>
            <Slot name='content'>
              <LiveView
                metrics={metrics}
                transformedData={transformedData}
                sectionAName={sectionA?.name}
                sectionBName={sectionB?.name}
              />
            </Slot>
          </Collapse>
        </Slot>
      </Card>
    )

  return (
    <Card className={className} {...rest}>
      <Slot name='header'>
        <MetricsHeader
          type={type}
          viewMode={viewMode}
          showDelta={showDelta}
          onDeltaToggle={toggleDelta}
          onViewModeChange={setViewMode}
          sectionAId={sectionA?.id}
          sectionBId={sectionB?.id}
        />
      </Slot>
      <Slot name='body'>
        {viewMode === 'historical' ? (
          <HistoricalView
            metrics={metrics}
            selectedMetric={selectedMetric}
            selectedMetricObj={selectedMetricObj}
            onMetricSelect={setSelectedMetric}
            transformedData={transformedData}
            showDelta={showDelta}
            sectionAName={sectionA?.name}
            sectionBName={sectionB?.name}
          />
        ) : (
          <LiveView
            metrics={metrics}
            transformedData={transformedData}
            sectionAName={sectionA?.name}
            sectionBName={sectionB?.name}
          />
        )}
      </Slot>
    </Card>
  )
}

export default MetricsCard
