import Box from '@/primitives/Box'
import Button from '@/primitives/Button'
import Card from '@/primitives/Card'
import FlexV2 from '@/primitives/FlexV2'
import Icon from '@/primitives/Icon'
import Text from '@/primitives/Text'
import { formatDate } from './utils'
import { getCurrentUser } from '@/reducers/selectors'
import strings from '@/components/TrialModeView/Strings'
import Slot from '@/primitives/Slot'
import { useModal } from '@/contexts/confirmation-modal'
import { MODAL_TYPES } from '@/contexts/confirmation-modal/config'
import ContentViewer from '@/components/Shared/RichEditor/ContentViewer'
import Separator from '@/primitives/Separator'

export const NoteList = ({ notes, isSubmitting, onDelete }) => {
  const currentUser = getCurrentUser()
  const { showModal } = useModal()

  const handleDeleteClick = note => {
    showModal({
      type: MODAL_TYPES.DELETE,
      data: note.id,
      onConfirm: noteId => {
        onDelete(noteId)
      }
    })
  }

  if (notes.length === 0) {
    return (
      <Box>
        <FlexV2 alignMainAxis='center' axisGap={200}>
          <Icon name='error_outline' />
          <Text>{strings.noNotesMessage}</Text>
        </FlexV2>
      </Box>
    )
  }

  return (
    <Box css={{ maxHeight: '80vh', overflow: 'auto' }}>
      <FlexV2 direction='column' axisGap={400}>
        {notes.map(note => (
          <Card
            key={note.id}
            style={{
              backgroundColor: 'var(--ctx-theme-color-page-200)',
              borderRadius: 10
            }}
          >
            <Slot name='body'>
              <FlexV2 alignMainAxis='space-between' alignCrossAxis='center'>
                <FlexV2 direction='column'>
                  <FlexV2 axisGap={200}>
                    <Icon name='person_outline' size={400} />
                    <Text fontWeight={500}>
                      {note.userDetails
                        ? `${note.userDetails.firstName} ${note.userDetails.lastName}`
                        : note.createdBy}
                    </Text>
                  </FlexV2>
                  <Text variant='neutral' tone={500} size={200}>
                    {formatDate(note.createdAt)}
                  </Text>
                </FlexV2>
                <FlexV2
                  axisGap={200}
                  alignCrossAxis='center'
                  alignMainAxis='flex-end'
                >
                  {currentUser?.userName === note.createdBy && (
                    <Button
                      variant='danger'
                      size='small'
                      iconBefore='delete'
                      disabled={isSubmitting}
                      onClick={() => handleDeleteClick(note)}
                    />
                  )}
                </FlexV2>
              </FlexV2>
              <Separator />
              <ContentViewer content={note.message} />
            </Slot>
          </Card>
        ))}
      </FlexV2>
    </Box>
  )
}
