import React from 'react'
import { useSelector } from 'react-redux'
import { getTrialState } from '../utils'
import {
  getDatasetLoading,
  getDashboardData,
  getMeasurements
} from '@/reducers/selectors'
import TrialModeViewHeader from '../components/Header'
import TrialSections from '../components/TrialSections'
import Loader from '@/primitives/Loader'
import FlexV2 from '@/primitives/FlexV2'
import MetricsCard from '../components/MetricsCard'
import BusinessInsights from '../components/BusinessInsights'
import { useTrialData } from '../hooks/useTrialData'
import { useMetrics } from '../hooks/useMetrics'
import { ErrorState } from '../components/ErrorState'
import { EmptyState } from '../components/EmptyState'
import TrialNotebook from '../components/TrialNotebook'
import useDeviceSize from '@/hooks/useDeviceSize'
import './index.scss'
import VirtualAssistant from '../components/VirtualAssistant'

export default function TrialModeViewDashboard() {
  const { currentTrial, currentTrialLoading, trialsLoading, error } =
    useSelector(getTrialState)
  const dashboardData = getDashboardData()
  const isDataSetLoading = getDatasetLoading()
  const measurements = getMeasurements()
  const isAdvancedUser = true
  const isTablet = useDeviceSize()
  const isMobile = useDeviceSize('mobile')
  const isSmallScreen = isMobile || isTablet
  const isLoading = currentTrialLoading || trialsLoading
  const { envMetrics, soilMetrics } = useMetrics(measurements, isAdvancedUser)
  useTrialData(currentTrial, envMetrics, soilMetrics)

  if (error) return <ErrorState message={error.message} />
  if (!currentTrial && !isLoading) return <EmptyState />

  return (
    <Loader isLoading={isLoading}>
      <FlexV2 direction='column' axisGap={300} className='trial-mode-dashboard'>
        {currentTrial && (
          <>
            <TrialModeViewHeader
              trial={currentTrial}
              title={currentTrial.name}
              status={currentTrial.status}
              startDate={currentTrial.startDate}
              endDate={currentTrial.endDate}
              isMobile={isMobile}
              isTablet={isTablet}
            />
            <TrialSections
              roomA={currentTrial.roomA}
              roomB={currentTrial.roomB}
              isSmallScreen={isSmallScreen}
            />
            <Loader isLoading={isDataSetLoading}>
              <FlexV2 direction='column' axisGap={300}>
                <MetricsCard
                  dashboardData={dashboardData}
                  sectionA={currentTrial.roomA}
                  sectionB={currentTrial.roomB}
                  metrics={envMetrics}
                  type='envirosense'
                  isSmallScreen={isSmallScreen}
                />
                <MetricsCard
                  dashboardData={dashboardData}
                  sectionA={currentTrial.roomA}
                  sectionB={currentTrial.roomB}
                  metrics={soilMetrics}
                  type='soilsense'
                  isSmallScreen={isSmallScreen}
                />
              </FlexV2>
            </Loader>
            <TrialNotebook trialId={currentTrial.id} />

            <BusinessInsights
              isSmallScreen={isSmallScreen}
              sectionA={currentTrial.roomA}
              sectionB={currentTrial.roomB}
            />
            <VirtualAssistant isSmallScreen={isSmallScreen} />
          </>
        )}
      </FlexV2>
    </Loader>
  )
}
