import Box from '@/primitives/Box'
import Text from '@/primitives/Text'

const MetricValue = ({ label, value, unit = '' }) => {
  return (
    <Box style={{ width: '50%' }}>
      <Text
        size={100}
        variant='page'
        tone={500}
        style={{ whiteSpace: 'nowrap' }}
      >
        {label}
      </Text>
      <Text
        as='div'
        size={400}
        fontWeight={700}
        style={{ whiteSpace: 'nowrap' }}
      >
        {typeof value === 'number' ? value.toFixed(1) : '—'}
        {unit && (
          <Text as='span' size={100} variant='page' tone={500}>
            {unit}
          </Text>
        )}
      </Text>
    </Box>
  )
}

export default MetricValue
