import React, { useState, useEffect, useMemo, useRef } from 'react'
import { CKEditor, useCKEditorCloud } from '@ckeditor/ckeditor5-react'
import { createEditorConfig } from './config'
import { initializePlugins } from './plugins'

import './index.scss'

const LICENSE_KEY = window.ENV.CK_EDITOR_LICENSE_KEY
const CLOUD_VERSION = '44.1.0'

const RichEditor = ({
  value,
  onChange,
  placeholder = '',
  initialData = '',
  parentId
}) => {
  const [isLayoutReady, setIsLayoutReady] = useState(false)
  const cloud = useCKEditorCloud({ version: CLOUD_VERSION })
  const editorContainerRef = useRef(null)
  const editorRef = useRef(null)

  useEffect(() => {
    setIsLayoutReady(true)
    return () => setIsLayoutReady(false)
  }, [])

  const { ClassicEditor, editorConfig } = useMemo(() => {
    if (cloud.status !== 'success' || !isLayoutReady) {
      return {}
    }

    const config = createEditorConfig(
      LICENSE_KEY,
      placeholder,
      initialData,
      parentId
    )
    return {
      ClassicEditor: cloud.CKEditor.ClassicEditor,
      editorConfig: {
        ...config,
        plugins: initializePlugins(cloud.CKEditor)
      }
    }
  }, [cloud, isLayoutReady, placeholder, initialData, parentId])

  return (
    <div className='main-container'>
      <div
        className='editor-container editor-container_classic-editor editor-container_include-block-toolbar'
        ref={editorContainerRef}
      >
        <div className='editor-container__editor'>
          <div ref={editorRef}>
            {ClassicEditor && editorConfig && (
              <CKEditor
                editor={ClassicEditor}
                config={editorConfig}
                data={value}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  onChange?.(data)
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RichEditor
