import React from 'react'
import Box from '@/primitives/Box'
import Text from '@/primitives/Text'

const InsightMetricValue = ({ label, value, unit, price }) => {
  return (
    <Box>
      <Text
        size={100}
        variant='page'
        tone={600}
        style={{ whiteSpace: 'nowrap' }}
      >
        {label}
      </Text>
      <Text
        as='div'
        size={400}
        fontWeight={700}
        style={{ whiteSpace: 'nowrap' }}
      >
        {typeof value === 'number' ? value.toFixed(0) : '—'}
        {unit && (
          <Text as='span' size={100} variant='page' tone={500}>
            {unit}
          </Text>
        )}
      </Text>
      <Text size={100} variant='page' tone={500}>
        {price}
      </Text>
    </Box>
  )
}

export default InsightMetricValue
