import React from 'react'
import Card from '@/primitives/Card'
import Slot from '@/primitives/Slot'
import FlexV2 from '@/primitives/FlexV2'
import Box from '@/primitives/Box'
import Icon from '@/primitives/Icon'
import Text from '@/primitives/Text'
import InsightMetricValue from './InsightMetricValue'
import strings from '@/components/TrialModeView/Strings'
import Button from '@/primitives/Button'

const InsightPanel = ({
  title,
  icon,
  data,
  isSmallScreen,
  sectionAName = strings.iyris,
  sectionBName = strings.control
}) => {
  const {
    sectionA,
    sectionB,
    variation,
    variationPercentage,
    isResourceSaving,
    price,
    unit
  } = data || {
    sectionA: { value: 0, price: 0 },
    sectionB: { value: 0, price: 0 },
    variation: 0,
    variationPercentage: 0
  }

  return (
    <Card>
      <Slot name='body'>
        <FlexV2
          alignCrossAxis='center'
          alignMainAxis='space-between'
          axisGap={100}
        >
          <FlexV2
            alignCrossAxis='center'
            alignMainAxis='space-between'
            axisGap={500}
          >
            <FlexV2 alignCrossAxis='center' axisGap={100}>
              <Icon name={icon} size={200} variant='neutral' tone={600} />
              <Text size={200} fontWeight={600} variant='neutral' tone={600}>
                {title}
              </Text>
            </FlexV2>
            <Box
              className={`percentage-badge ${
                variationPercentage < 0 && isResourceSaving
                  ? 'success'
                  : 'danger'
              }`}
            >
              <Icon
                name={
                  variationPercentage > 0 ? 'arrow_upward' : 'arrow_downward'
                }
                size={100}
                variant='neutral'
                tone={100}
              />
              <Text size={100} fontWeight={600} variant='neutral' tone={100}>
                {Math.abs(variationPercentage)}%
              </Text>
            </Box>
          </FlexV2>
          <Button size='small' variant='outline' iconBefore='edit'>
            {price}/{unit}
          </Button>
        </FlexV2>
        {isSmallScreen ? (
          <FlexV2 direction='column' axisGap={500}>
            <FlexV2 alignMainAxis='space-between' axisGap={200}>
              <Box style={{ width: '50%' }}>
                <InsightMetricValue
                  label={sectionAName}
                  value={sectionA.value}
                  unit={unit}
                  price={sectionA.price}
                />
              </Box>
              <Box style={{ width: '50%' }}>
                <InsightMetricValue
                  label={sectionBName}
                  value={sectionB.value}
                  unit={unit}
                  price={sectionB.price}
                />
              </Box>
            </FlexV2>
            <Box>
              <Text size={100} variant='page' tone={500}>
                {strings.savingsLabel}
              </Text>
              <FlexV2 alignCrossAxis='center' axisGap={100}>
                <Text
                  size={400}
                  fontWeight={700}
                  variant={variation > 0 ? 'success' : 'danger'}
                  tone={700}
                >
                  {variation} {unit}
                </Text>
              </FlexV2>
              <Text size={100} variant='page' tone={500}>
                {data?.priceDifference || 0}
              </Text>
            </Box>
          </FlexV2>
        ) : (
          <FlexV2 alignMainAxis='space-between' direction='row' axisGap={500}>
            <Box style={{ width: '33%' }}>
              <InsightMetricValue
                label={sectionAName}
                value={sectionA.value}
                unit={unit}
                price={sectionA.price}
              />
            </Box>
            <Box style={{ width: '33%' }}>
              <InsightMetricValue
                label={sectionBName}
                value={sectionB.value}
                unit={unit}
                price={sectionB.price}
              />
            </Box>
            <Box style={{ width: '33%' }}>
              <Text size={100} variant='page' tone={500}>
                {strings.savingsLabel}
              </Text>
              <FlexV2 alignCrossAxis='center' axisGap={100}>
                <Text
                  size={400}
                  fontWeight={700}
                  variant={variation > 0 ? 'success' : 'danger'}
                  tone={700}
                >
                  {variation} {unit}
                </Text>
              </FlexV2>
              <Text size={100} variant='page' tone={500}>
                {data?.priceDifference || 0}
              </Text>
            </Box>
          </FlexV2>
        )}
      </Slot>
    </Card>
  )
}

export default InsightPanel
