import Box from '@/primitives/Box'
import Card from '@/primitives/Card'
import FlexV2 from '@/primitives/FlexV2'
import Grid from '@/primitives/Grid'
import Text from '@/primitives/Text'
import MetricValue from './MetricValue'
import Slot from '@/primitives/Slot'

const LiveView = ({ metrics, transformedData, sectionAName, sectionBName }) => {
  const latestData = transformedData[transformedData.length - 1] || {}

  return (
    <Grid
      className='live-view-grid'
      templateColumns='repeat(auto-fit, minmax(250px, 1fr))'
      gap={400}
    >
      {metrics.map(metric => {
        const sectionA = latestData[`sectionA_${metric.id}`]
        const sectionB = latestData[`sectionB_${metric.id}`]

        return (
          <Box key={metric.id}>
            <Card>
              <Slot name='body'>
                <FlexV2 alignCrossAxis='center' axisGap={200}>
                  <Text size={200} fontWeight={600} variant='page' tone={800}>
                    {metric.title}
                  </Text>
                </FlexV2>

                <FlexV2 axisGap={400}>
                  <MetricValue
                    label={sectionAName}
                    value={sectionA}
                    unit={metric.unitLabel}
                  />
                  <MetricValue
                    label={sectionBName}
                    value={sectionB}
                    unit={metric.unitLabel}
                  />
                </FlexV2>
              </Slot>
            </Card>
          </Box>
        )
      })}
    </Grid>
  )
}

export default LiveView
