import React from 'react'
import Box from '@/primitives/Box'
import Text from '@/primitives/Text'
import { STATUS_STYLES, TRIAL_STATUS } from '../Header/constants'
import strings from '../../Strings'
import './index.scss'

export const StatusBadge = ({ status }) => {
  status = status.toLowerCase()
  const style = STATUS_STYLES[status] || STATUS_STYLES[TRIAL_STATUS.PLANNED]

  return (
    <Box className='status-badge'>
      <Box
        className='status-badge__dot'
        style={{ backgroundColor: style.dotColor }}
      />
      <Text className='status-badge__text' style={{ color: style.textColor }}>
        {strings[status]}
      </Text>
    </Box>
  )
}

export default StatusBadge
