import { S3Service } from '@/Util/s3Service'
import { S3UploadAdapter } from './s3UploadAdapter'

export const AWS_EDITOR_UPLOAD_BUCKET = window.ENV.AWS_EDITOR_UPLOAD_BUCKET
export const AWS_EDITOR_UPLOAD_BUCKET_PREFIX = 'editor-uploads'
export const editorS3Service = new S3Service(AWS_EDITOR_UPLOAD_BUCKET)

export const createEditorConfig = (
  LICENSE_KEY,
  placeholder,
  initialData,
  parentId
) => ({
  toolbar: {
    items: [
      'insertImage',
      '|',
      'bulletedList',
      'numberedList',
      'todoList',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'heading',
      '|',
      'fontSize',
      'fontFamily',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'link',
      'insertTable',
      'highlight',
      'blockQuote',
      '|',
      'outdent',
      'indent'
    ],
    shouldNotGroupWhenFull: false
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1'
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2'
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3'
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4'
      },
      {
        model: 'heading5',
        view: 'h5',
        title: 'Heading 5',
        class: 'ck-heading_heading5'
      },
      {
        model: 'heading6',
        view: 'h6',
        title: 'Heading 6',
        class: 'ck-heading_heading6'
      }
    ]
  },
  image: {
    upload: {
      types: ['jpeg', 'png', 'gif', 'webp']
    },
    toolbar: [
      'toggleImageCaption',
      'imageTextAlternative',
      '|',
      'imageStyle:inline',
      'imageStyle:wrapText',
      'imageStyle:breakText',
      '|',
      'resizeImage'
    ]
  },
  extraPlugins: [
    function (editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        return new S3UploadAdapter(loader, parentId)
      }
    }
  ],
  balloonToolbar: [
    'bold',
    'italic',
    '|',
    'link',
    'insertImage',
    '|',
    'bulletedList',
    'numberedList'
  ],
  blockToolbar: [
    'fontSize',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    '|',
    'link',
    'insertImage',
    'insertTable',
    '|',
    'bulletedList',
    'numberedList',
    'outdent',
    'indent'
  ],
  licenseKey: LICENSE_KEY,
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://',
    decorators: {
      toggleDownloadable: {
        mode: 'manual',
        label: 'Downloadable',
        attributes: { download: 'file' }
      }
    }
  },
  list: {
    properties: {
      styles: true,
      startIndex: true,
      reversed: true
    }
  },
  initialData,
  placeholder,
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableProperties',
      'tableCellProperties'
    ]
  }
})
