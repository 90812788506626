import React, { useEffect, useRef } from 'react'
import DOMPurify from 'dompurify'
import {
  AWS_EDITOR_UPLOAD_BUCKET,
  AWS_EDITOR_UPLOAD_BUCKET_PREFIX,
  editorS3Service
} from './config'

const ContentViewer = ({ content }) => {
  const containerRef = useRef(null)

  const extractS3Key = url => {
    try {
      if (!url?.includes(AWS_EDITOR_UPLOAD_BUCKET)) {
        return null
      }

      const urlParts = url.split(
        `${AWS_EDITOR_UPLOAD_BUCKET}.s3.${window.ENV.REGION}.amazonaws.com/`
      )
      if (urlParts.length < 2) return null

      const fullPath = urlParts[1].split('?')[0]

      if (fullPath.includes(`${AWS_EDITOR_UPLOAD_BUCKET_PREFIX}/`)) {
        const match = fullPath.match(
          new RegExp(`${AWS_EDITOR_UPLOAD_BUCKET_PREFIX}\\/.*`)
        )
        return match ? match[0] : null
      }

      return fullPath
    } catch (error) {
      return null
    }
  }

  const refreshImage = async img => {
    try {
      const src = img.getAttribute('src')
      const key = extractS3Key(src)

      if (key) {
        const newUrl = await editorS3Service.getPresignedUrl(key)
        img.src = newUrl
      }
    } catch (error) {
      // console.error('Failed to refresh image:', error)
    }
  }

  useEffect(() => {
    if (!containerRef.current) return

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          if (node.nodeName === 'IMG') {
            refreshImage(node)
          }
        })
      })
    })

    observer.observe(containerRef.current, {
      childList: true,
      subtree: true
    })

    const images = containerRef.current.getElementsByTagName('img')
    Array.from(images).forEach(refreshImage)

    return () => {
      observer.disconnect()
    }
  }, [content])

  return (
    <div
      ref={containerRef}
      className='html-content-viewer'
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
    />
  )
}

export default ContentViewer
