import React from 'react'
import Card from '@/primitives/Card'
import Image from '@/primitives/Image'
import Text from '@/primitives/Text'
import Slot from '@/primitives/Slot'
import Box from '@/primitives/Box'
import './index.scss'
import Collapse from '@/primitives/Collapse'

const SectionCard = ({
  section,
  color,
  showLogo,
  children,
  showAsCollapsed
}) => (
  <Card className={`section-card section-card--${color}`}>
    <Slot name='body'>
      {showAsCollapsed ? (
        <Collapse defaultOpen={false}>
          <Slot name='trigger'>
            <Text size={300} fontWeight={600}>
              {section}
            </Text>
            {showLogo && (
              <Image
                src='/iyris.svg'
                alt='Iyris Logo'
                className='section-card__logo'
              />
            )}
          </Slot>

          <Slot name='content'>
            <Box className='section-card__content'>{children}</Box>
          </Slot>
        </Collapse>
      ) : (
        <>
          <Text size={500} fontWeight={600}>
            {section}
          </Text>
          {showLogo && (
            <Image
              src='/iyris.svg'
              alt='Iyris Logo'
              className='section-card__logo'
            />
          )}
          <Box className='section-card__content'>{children}</Box>
        </>
      )}
    </Slot>
  </Card>
)

export default SectionCard
