import React from 'react'
import FlexV2 from '@/primitives/FlexV2'
import Text from '@/primitives/Text'

const InfoRow = ({ label, value }) => (
  <FlexV2 axisGap={0} direction='column'>
    <Text fontWeight={600} size={100} variant='neutral' tone={600}>
      {label}
    </Text>
    <Text>{value}</Text>
  </FlexV2>
)

export default InfoRow
